import React from 'react'
import Header from '../Components/Header'
import BlogContent from '../Components/BlogContent'
import Footer from '../Components/Footer'

const Blog = () => {
  return (
    <div>
    <Header/> 
    <BlogContent/>
    <Footer/>
    </div>
  )
}
export default Blog