import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/database"
const firebaseConfig = {
    apiKey: "AIzaSyDcDRESK96MzVRQt0GcLuczyqQoYuUDIMU",
  authDomain: "photography-67871.firebaseapp.com",
  projectId: "photography-67871",
  storageBucket: "photography-67871.appspot.com",
  messagingSenderId: "1014760362605",
  appId: "1:1014760362605:web:dba05aafcbfbb9aa372564"
};

const fire=firebase.initializeApp(firebaseConfig);
export default fire.database().ref()