import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import GalleryBackgroundBanner from '../Components/GalleryBackgroundBanner'
import Pulsecontent from '../Components/Pulsecontent'

const Pulse = () => {
  return (
    <div className='projects1'>
      <Header/>
      <main className="ms-main ms-main-responsive ">
                <GalleryBackgroundBanner title="Pulse of After Hour"/>
                <Pulsecontent/>
            </main>
      <Footer/>
    </div>
  )
}

export default Pulse
