import React from 'react'
import Header from '../Components/Header'
import AboutMeContent from '../Components/AboutMeContent'
import Footer from '../Components/Footer'

const AboutMe = () => {
  return (
    <div>
        <Header/>
        <AboutMeContent/>
        <Footer/>
    </div>
  )
}

export default AboutMe