import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import GalleryBackgroundBanner from '../Components/GalleryBackgroundBanner'
import Printscontent from '../Components/Printscontent'

const Prints = () => {
  return (
    <div className='projects1'>
      <Header/>
      <main className="ms-main ms-main-responsive ">
                <GalleryBackgroundBanner title="Prints"/>
                <Printscontent/>
            </main>
      <Footer/>
    </div>
  )
}

export default Prints
