import React from 'react'
import Masonry from 'react-masonry-css';
const Lifestylecontent = () => {
    const breakpointColumnsObj = {
        default: 3,
        1100: 3,
        700: 2
    };
  return (
    <div className='masonry' >
    <div className="ms-portfolio-filter-area  project">
        <div className="container">
            <div className="portfolio_wrap">
            <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="filter grid-masonary row ms-masonry-gallery portfolio-feed"
                    columnClassName="ms-masonry-grid_column">
                    <div className="center h-align-middle grid-item-p element-item transition creative col-md-4" data-category="transition">
                        <div className="item--inner">
                            <a aria-label="Freezing Birthday">
                                <figure className="ms-p-img">
                                    <img decoding="async" src="./images/Lifestyle Portraits/Image1.PNG" alt="Freezing Birthday" />
                                </figure>
                                {/* <div className="ms-p-content"> */}
                                    {/* <h3>LifeStyle Portraits</h3> */}
                                    {/* <h4 className="ms-p-cat">creative</h4> */}
                                {/* </div> */}
                            </a>
                        </div>
                    </div>
                    <div className="center h-align-middle grid-item-p element-item transition design col-md-4" data-category="transition">
                        <div className="item--inner">
                            <a  aria-label="Freezing Birthday">
                                <figure className="ms-p-img">
                                    <img decoding="async" src="./images/Lifestyle Portraits/Image2.PNG" alt="Freezing Birthday" />
                                </figure>
                            </a>
                        </div>
                    </div>
                    <div className="center h-align-middle grid-item-p element-item transition photo col-md-4" data-category="transition">
                        <div className="item--inner">
                            <a  aria-label="Freezing Birthday">
                                <figure className="ms-p-img">
                                    <img decoding="async" src="./images/Lifestyle Portraits/Image3.PNG" alt="Freezing Birthday" />
                                </figure>
                             
                            </a>
                        </div>
                    </div>
                    <div className=" center h-align-middle grid-item-p element-item transition style col-md-4" data-category="transition">
                        <div className="item--inner">
                            <a  aria-label="Freezing Birthday">
                                <figure className="ms-p-img">
                                    <img decoding="async" src="./images/Lifestyle Portraits/Image4.PNG" alt="Freezing Birthday" />
                                </figure>
                               
                            </a>
                        </div>
                    </div>
                    <div className=" center h-align-middle grid-item-p element-item transition creative col-md-4" data-category="transition">
                        <div className="item--inner">
                            <a  aria-label="Freezing Birthday">
                                <figure className="ms-p-img">
                                    <img decoding="async" src="./images/Lifestyle Portraits/Image5.PNG" alt="Freezing Birthday" />
                                </figure>
                               
                            </a>
                        </div>
                    </div>
                    <div className=" center h-align-middle grid-item-p element-item transition design col-md-4" data-category="transition">
                        <div className="item--inner">
                            <a  aria-label="Freezing Birthday">
                                <figure className="ms-p-img">
                                    <img decoding="async" src="./images/Lifestyle Portraits/Image6.PNG" alt="Freezing Birthday" />
                                </figure>
                              
                            </a>
                        </div>
                    </div>
                    <div className=" center h-align-middle grid-item-p element-item transition photo col-md-4" data-category="transition">
                        <div className="item--inner">
                            <a  aria-label="Freezing Birthday">
                                <figure className="ms-p-img">
                                    <img decoding="async" src="./images/Lifestyle Portraits/Image7.PNG" alt="Freezing Birthday" />
                                </figure>
                               
                            </a>
                        </div>
                    </div>
                    <div className=" center h-align-middle grid-item-p element-item transition col-md-4" data-category="transition">
                        <div className="item--inner">
                            <a  aria-label="Freezing Birthday">
                                <figure className="ms-p-img">
                                    <img decoding="async" src="./images/Lifestyle Portraits/Image8.PNG" alt="Freezing Birthday" />
                                </figure>
                               
                            </a>
                        </div>
                    </div>
                    <div className=" center h-align-middle grid-item-p element-item transition creative photo  col-md-4" data-category="transition">
                        <div className="item--inner">
                            <a  aria-label="Freezing Birthday">
                                <figure className="ms-p-img">
                                    <img decoding="async" src="./images/Lifestyle Portraits/Image9.PNG" alt="Freezing Birthday" />
                                </figure>
                               
                            </a>
                        </div>
                    </div>
                    <div className=" center h-align-middle grid-item-p element-item transition creative photo  col-md-4" data-category="transition">
                        <div className="item--inner">
                            <a  aria-label="Freezing Birthday">
                                <figure className="ms-p-img">
                                    <img decoding="async" src="./images/Lifestyle Portraits/Image10.PNG" alt="Freezing Birthday" />
                                </figure>
                                
                            </a>
                        </div>
                    </div>
                    <div className=" center h-align-middle grid-item-p element-item transition creative photo  col-md-4" data-category="transition">
                        <div className="item--inner">
                            <a  aria-label="Freezing Birthday">
                                <figure className="ms-p-img">
                                    <img decoding="async" src="./images/Lifestyle Portraits/Image11.PNG" alt="Freezing Birthday" />
                                </figure>
                                
                            </a>
                        </div>
                    </div>
                    <div className=" center h-align-middle grid-item-p element-item transition creative photo  col-md-4" data-category="transition">
                        <div className="item--inner">
                            <a  aria-label="Freezing Birthday">
                                <figure className="ms-p-img">
                                    <img decoding="async" src="./images/Lifestyle Portraits/Image12.PNG" alt="Freezing Birthday" />
                                </figure>
                                
                            </a>
                        </div>
                    </div>
                    <div className=" center h-align-middle grid-item-p element-item transition creative photo  col-md-4" data-category="transition">
                        <div className="item--inner">
                            <a  aria-label="Freezing Birthday">
                                <figure className="ms-p-img">
                                    <img decoding="async" src="./images/Lifestyle Portraits/Image13.PNG" alt="Freezing Birthday" />
                                </figure>
                                
                            </a>
                        </div>
                    </div>
                    <div className=" center h-align-middle grid-item-p element-item transition creative photo  col-md-4" data-category="transition">
                        <div className="item--inner">
                            <a aria-label="Freezing Birthday">
                                <figure className="ms-p-img">
                                    <img decoding="async" src="./images/Lifestyle Portraits/Image14.PNG" alt="Freezing Birthday" />
                                </figure>
                                
                            </a>
                        </div>
                    </div>
                    <div className=" center h-align-middle grid-item-p element-item transition creative photo  col-md-4" data-category="transition">
                        <div className="item--inner">
                            <a aria-label="Freezing Birthday">
                                <figure className="ms-p-img">
                                    <img decoding="async" src="./images/Lifestyle Portraits/Image15.PNG" alt="Freezing Birthday" />
                                </figure>
                               
                            </a>
                        </div>
                    </div>
                    <div className=" center h-align-middle grid-item-p element-item transition creative photo  col-md-4" data-category="transition">
                        <div className="item--inner">
                            <a aria-label="Freezing Birthday">
                                <figure className="ms-p-img">
                                    <img decoding="async" src="./images/Lifestyle Portraits/Image16.PNG" alt="Freezing Birthday" />
                                </figure>
                               
                            </a>
                        </div>
                    </div>
                    <div className=" center h-align-middle grid-item-p element-item transition creative photo  col-md-4" data-category="transition">
                        <div className="item--inner">
                            <a aria-label="Freezing Birthday">
                                <figure className="ms-p-img">
                                    <img decoding="async" src="./images/Lifestyle Portraits/Image17.PNG" alt="Freezing Birthday" />
                                </figure>
                               
                            </a>
                        </div>
                    </div>
                    <div className=" center h-align-middle grid-item-p element-item transition creative photo  col-md-4" data-category="transition">
                        <div className="item--inner">
                            <a aria-label="Freezing Birthday">
                                <figure className="ms-p-img">
                                    <img decoding="async" src="./images/Lifestyle Portraits/Image18.PNG" alt="Freezing Birthday" />
                                </figure>
                                
                            </a>
                        </div>
                    </div>
                    <div className=" center h-align-middle grid-item-p element-item transition creative photo  col-md-4" data-category="transition">
                        <div className="item--inner">
                            <a aria-label="Freezing Birthday">
                                <figure className="ms-p-img">
                                    <img decoding="async" src="./images/Lifestyle Portraits/Image19.PNG" alt="Freezing Birthday" />
                                </figure>
                              
                            </a>
                        </div>
                    </div>
                    <div className=" center h-align-middle grid-item-p element-item transition creative photo  col-md-4" data-category="transition">
                        <div className="item--inner">
                            <a  aria-label="Freezing Birthday">
                                <figure className="ms-p-img">
                                    <img decoding="async" src="./images/Lifestyle Portraits/Image20.PNG" alt="Freezing Birthday" />
                                </figure>
                              
                            </a>
                        </div>
                    </div>
                    <div className=" center h-align-middle grid-item-p element-item transition creative photo  col-md-4" data-category="transition">
                        <div className="item--inner">
                            <a aria-label="Freezing Birthday">
                                <figure className="ms-p-img">
                                    <img decoding="async" src="./images/Lifestyle Portraits/Image21.PNG" alt="Freezing Birthday" />
                                </figure>
                               
                            </a>
                        </div>
                    </div>
                    {/* <div className=" center h-align-middle grid-item-p element-item transition creative photo  col-md-4" data-category="transition">
                        <div className="item--inner">
                            <a  aria-label="Freezing Birthday">
                                <figure className="ms-p-img">
                                    <img decoding="async" src="./images/LifeStyle Portraits/Street Portraits-22.jpg" alt="Freezing Birthday" />
                                </figure>
                                
                            </a>
                        </div>
                    </div> */}
                    {/* <div className=" center h-align-middle grid-item-p element-item transition creative photo  col-md-4" data-category="transition">
                        <div className="item--inner">
                            <a  aria-label="Freezing Birthday">
                                <figure className="ms-p-img">
                                    <img decoding="async" src="./images/LifeStyle Portraits/Street Portraits-23.jpg" alt="Freezing Birthday" />
                                </figure>
                               
                            </a>
                        </div>
                    </div> */}
                </Masonry>
            </div>
        </div>
    </div>
</div>
  )
}

export default Lifestylecontent
