import React from 'react'
import "../AdminComponents/Style.css"
const BlogContent = () => {
    return (
        <div>
            <main className="ms-main ms-single-post inner ms-main-responsive ">
                <div className="ms-single-post--img default">
                    <figure className="media-wrapper media-wrapper--21:1">
                        <img className="attachment-most-default-post-thumb size-most-default-post-thumb wp-post-image" src="assets/images/Hollywell-12.PNG"   alt="UI vs. UX: What’s the difference?"  />
                    </figure>
                </div>
                <header className="ms-sp--header">
                    {/* <div className="post-category__list">
                        <ul className="post-categories cat-tag">
                            <li><a href="#0" rel="category tag">Photography</a></li>
                        </ul>
                    </div> */}
                    {/* <div className="container main-headings">
                        <h1 className="heading-title single">Moments and Memories Captured through the Lens</h1>
                        <div className="post-meta-date meta-date-sp">
                            <span className="post-author__name">By:- Sumit</span>
                            <span>20 March, 2024</span>
                        </div>
                    </div> */}
                </header>
                <article style={{marginBottom:"8%"}} className="ms-sp--article">
                    <div className="entry-content divpara">
                        {/* <h3><strong>Exploring Light and Shadow: A Study in Contrast.</strong></h3> */}
                        <p>Embark on a photographic journey with The Afterlife Studio that transcends the ordinary. Here, you're not just another client; you're the muse, the heartbeat of a unique storytelling experience. As you step in front of the lens, leave the world behind and embrace a moment that's all about you- your life, your joy, your raw, unfiltered essence.This isn't just a photoshoot; it's a celebration of you. It's where spontaneity meets artistry in a symphony of light and shadow. Feel at ease as you're guided through each pose, each frame, with a sense of playfulness and discovery. Together, we'll craft images that echo with laughter, whisper with intimacy, and resonate with the unspoken depths of your spirit.
                        Expect laughter, expect warmth, expect a touch of the unexpected as we capture the intangible magic that is uniquely yours. These images will be more than just photographs; they will be timeless treasures, a tapestry of moments that speak of a life richly lived and deeply loved.
                        </p>
                        <p> With The Afterlife Studio, you're not just getting photos; you're gaining memories that will accompany you, images that will stand as a testament to who you are, beyond the now, into the forever. Welcome to an experience that is as immortal as the stories we'll capture.
                         With a heart beating to the rhythm of shutter clicks, I've journeyed through diverse realms of photography-from the first breaths of newborns to the electric pulse of nightlife. My camera has been my loyal companion, capturing the essence of life's myriad dances. Whether it's the tender moments of maternity or the vibrant energy of events, each click is a story, each photograph, a preserved slice of eternity
                        
                        In this portfolio, you'll wander through galleries where dreams are etched onto digital canvases, and creativity spills from every pixel. Welcome to The Afterlife Studio, where every frame is an echo of infinity, and every image is a whisper of the soul's endless creativity.
                        </p>
                    </div>
                </article>
            </main>
        </div>
    )
}
export default BlogContent