const Api = [{
    Name: "DSC00208",
    Category: "Lifestyle Portraits",
    Image: "./images/Lifestyle Portraits/DSC00208.JPEG"
},{
    Name: "DSC00005-Edit",
    Category: "Portrait Gallery",
    Image: "./images/Portrait Gallery/DSC00005-Edit.JPEG"
},{
    Name: "DSC00607",
    Category: "Lifestyle Portraits",
    Image: "./images/Lifestyle Portraits/DSC00607.JPEG"
},{
    Name: "DSC00020-Edit",
    Category: "Portrait Gallery",
    Image: "./images/Portrait Gallery/DSC00020-Edit.JPEG"
},{
    Name: "DSC00616",
    Category: "Lifestyle Portraits",
    Image: "./images/Lifestyle Portraits/DSC00616.JPEG"
},{
    Name: "DSC05807",
    Category: "Prints",
    Image: "./images/Prints/DSC05807.JPEG"
},{
    Name: "DSC00033-Edit",
    Category: "Portrait Gallery",
    Image: "./images/Portrait Gallery/DSC00033-Edit.JPG"
},{
    Name: "DSC00671",
    Category: "Lifestyle Portraits",
    Image: "./images/Lifestyle Portraits/DSC00671.JPEG"
},{
    Name: "DSC03265",
    Category: "Lifestyle Portraits",
    Image: "./images/Lifestyle Portraits/DSC03265.JPEG"
},{
    Name: "DSC04815",
    Category: "Prints",
    Image: "./images/Prints/DSC04815.JPEG"
},{
    Name: "DSC00037_Original",
    Category: "Portrait Gallery",
    Image: "./images/Portrait Gallery/DSC00037_Original.jpg"
},{
    Name: "IMG_2586",
    Category: "Prints",
    Image: "./images/Prints/IMG_2586.JPEG"
},{
    Name: "DSC00143_Original",
    Category: "Portrait Gallery",
    Image: "./images/Portrait Gallery/DSC00143_Original.jpg"
},{
    Name: "Hollywell-44.JPEG",
    Category: "Pulse of After hour",
    Image: "./images/Pulse of After hour/Hollywell-44.JPEG"
},{
    Name: "DSC03319",
    Category: "Lifestyle Portraits",
    Image: "./images/Lifestyle Portraits/DSC03319.JPEG"
},{
    Name: "Hollywell-40.JPEG",
    Category: "Pulse of After hour",
    Image: "./images/Pulse of After hour/Hollywell-40.JPEG"
},{
    Name: "Hollywell-1",
    Category: "Pulse of After hour",
    Image: "./images/Pulse of After hour/Hollywell-1.JPEG"
},{
    Name: "DSC00218-Edit-Edit",
    Category: "Portrait Gallery",
    Image: "./images/Portrait Gallery/DSC00218-Edit-Edit.JPG"
},{
    Name: "DSC03583",
    Category: "Portrait Gallery",
    Image: "./images/Portrait Gallery/DSC03583.JPEG"
},{
    Name: "DSC07039",
    Category: "Lifestyle Portraits",
    Image: "./images/Lifestyle Portraits/DSC07039.JPEG"
},{
    Name: "DSC07702",
    Category: "Lifestyle Portraits",
    Image: "./images/Lifestyle Portraits/DSC07702.JPEG"
},{
    Name: "ATK00128",
    Category: "Pulse of After hour",
    Image: "./images/Pulse of After hour/DSC00128.JPEG"
},{
    Name: "DSC03704",
    Category: "Portrait Gallery",
    Image: "./images/Portrait Gallery/DSC03583.JPEG"
},{
    Name: "IMG_4849",
    Category: "Lifestyle Portraits",
    Image: "./images/Lifestyle Portraits/IMG_4849.JPG"
},{
    Name: "DSC00119",
    Category: "Pulse of After hour",
    Image: "./images/Pulse of After hour/DSC00119.JPEG"
},{
    Name: "DSC07782",
    Category: "Prints",
    Image: "./images/Prints/DSC07782.JPEG"
},{
    Name: "IMG_4850",
    Category: "Lifestyle Portraits",
    Image: "./images/Lifestyle Portraits/IMG_4850.JPG"
},{
    Name: "DSC03770",
    Category: "Portrait Gallery",
    Image: "./images/Portrait Gallery/DSC03583.JPEG"
},{
    Name: "OXF110223-24",
    Category: "Lifestyle Portraits",
    Image: "./images/Lifestyle Portraits/OXF110223-24.JPEG"
},{
    Name: "OXF190223-001",
    Category: "Lifestyle Portraits",
    Image: "./images/Lifestyle Portraits/OXF190223-001.JPEG"
},{
    Name: "ATK00092",
    Category: "Pulse of After hour",
    Image: "./images/Pulse of After hour/ATK00092.jpg"
},{
    Name: "OXF190223-004",
    Category: "Lifestyle Portraits",
    Image: "./images/Lifestyle Portraits/OXF190223-004.JPEG"
},{
    Name: "ATK00047",
    Category: "Pulse of After hour",
    Image: "./images/Pulse of After hour/ATK00047.jpg"
},{
    Name: "DSC04149",
    Category: "Portrait Gallery",
    Image: "./images/Portrait Gallery/DSC04149.JPEG"
},{
    Name: "ATK00027",
    Category: "Pulse of After hour",
    Image: "./images/Pulse of After hour/ATK00027.jpg"
},{
    Name: "DSC00128",
    Category: "Pulse of After hour",
    Image: "./images/Pulse of After hour/DSC00128.JPEG"
},{
    Name: "DSC04266",
    Category: "Portrait Gallery",
    Image: "./images/Portrait Gallery/DSC04266.JPEG"
},{
    Name: "075",
    Category: "Pulse of After hour",
    Image: "./images/Pulse of After hour/075.jpeg"
},{
    Name: "ATK00350",
    Category: "Pulse of After hour",
    Image: "./images/Pulse of After hour/ATK00350.jpeg"
},{
    Name: "OXF190223-011",
    Category: "Lifestyle Portraits",
    Image: "./images/Lifestyle Portraits/OXF190223-011.JPEG"
},{
    Name: "021",
    Category: "Pulse of After hour",
    Image: "./images/Pulse of After hour/021.jpeg"
}
,{
    Name: "053",
    Category: "Pulse of After hour",
    Image: "./images/Pulse of After hour/053.jpeg"
},{
    Name: "OXF190223-021",
    Category: "Lifestyle Portraits",
    Image: "./images/Lifestyle Portraits/OXF190223-021.JPEG"
},{
    Name: "DSC07713",
    Category: "Prints",
    Image: "./images/Prints/DSC07713.JPEG"
},{
    Name: "045",
    Category: "Pulse of After hour",
    Image: "./images/Pulse of After hour/045.jpeg"
},{
    Name: "IMG_4847",
    Category: "Portrait Gallery",
    Image: "./images/Portrait Gallery/IMG_4847.JPG"
},{
    Name: "DSC00146",
    Category: "Pulse of After hour",
    Image: "./images/Pulse of After hour/DSC00146.jpeg"
},{
    Name: "OXF190223-007",
    Category: "Lifestyle Portraits",
    Image: "./images/Lifestyle Portraits/OXF190223-007.JPEG"
}]
export default Api