import React from 'react'
import Header from '../Components/Header'
import AdminLoginComponent from '../Components/AdminLoginComponent'
import Footer from '../Components/Footer'

const AdminLogin = () => {
  return (
    <div className='projects' >
        <Header/>
        <AdminLoginComponent/>
        <Footer/>
         
    </div>
  )
}

export default AdminLogin