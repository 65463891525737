import React, { useEffect, useRef, useState } from 'react'
import Firebase from '../Firebase'
import EditContact from './EditContact'
import "./Style.css";
import JsPDF from 'jspdf';
import DataTable from 'react-data-table-component';
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const ContactDetail = () => {
    const [Obj,SetObj]=useState("")
    const [Obj1,SetObj1]=useState("")
    const[Search,SetSearch]=useState("")
    const [Key,SetKey]=useState("")
    const [Secret,SetSecret]=useState("AfterLifeStudio")
    const [Condition,SetCondition]=useState(false)
    const navigate=useNavigate()
    const Columns = [
      {
        name: 'S.No.',
        selector: (key,index) => index+1,
      },
      {
        name: 'Name',
        selector: key => Obj[key]?.Name,
      },
      {
        name: 'Phone Number',
        selector: key => Obj[key]?.Phone,
      },
      {
        name: 'Email',
        selector: key => Obj[key]?.Email,
      },
      {
        name: 'Message',
        selector: key => Obj[key]?.Message,
      },
      {
        name: 'Date',
        selector: key => Obj[key]?.Date,
      },
    ];
    useEffect(function()
    {
        Firebase.child("Contacts").on("value",function(snap)
        {
            if(snap.val()==null)
            {
                SetObj("")
                SetObj1("")
            }
            else
            {
                SetObj(snap.val())
                SetObj1(snap.val())
            }
        })
    },[])
    function Delete(key)
    {
       const result= window.confirm("Do you want to delete?")
       if(result)
       {
        const response=window.prompt("Enter Secret Key to delete?")
        if(response)
        {
          if(response==Secret)
        {
          Firebase.child(`Contacts/${key}`).remove(err=>{
            if(err)
            {
                toast.error("Error occured")
            }
            else
            {
                toast.success("Contact Deleted") 
            }
            })
        }
        else
        {
          toast.warning("Wrong Key")
          toast.error("Access Failed")
          setTimeout(() => {
            navigate("/")
          }, 1500);
        }
        }
       }
    }
    function Edit(key)
    {
      const response=window.prompt("Enter a Secret key to edit a Contact?")
      if(response)
      { 
        if(response==Secret)
        {
          SetKey(key)
          SetCondition(true)
        }
        else
        {
          alert("Wrong Key")
          alert("Access Failed")
          navigate("/")
        }
      }
    }
    function NotEdit()
    {
        SetKey("")
        SetCondition(false)   
    }
    function search(event)
    {
      const response = event.target.value.toLowerCase().replace(/[^a-zA-Z0-9\s@.,]/g, ''); // Allow only letters, digits, spaces, @, ., and commas
      if(response=="")
      {
        SetObj(Obj1);
        SetSearch(response.trim());
      }
      else
      {
        SetSearch(response);
        let obj={}
        Object.keys(Obj1).filter((key)=>{
          if(Obj1[key].Name.toLowerCase().match(response.trim()) || Obj1[key].Phone.match(response.trim()) || Obj1[key].Email.match(response.trim()))
          {
            obj[key]=Obj1[key]
          }
        })
        if(Object.keys(obj).length==0)
        {
          SetObj("")
        }
        else
        {
          SetObj({...obj})
        }
      }
    }
    function Deletee()
    {
      const response=window.confirm("Do you want to delete?")
      if(response)
      {
       const key= window.prompt("Enter a Secret Key")
       if(key)
       {
        if(key==Secret)
        {
            Firebase.child("Contacts").remove(err=>{
              if(err)
              {
                toast.error("Error Occured")
              }
              else
              {
                toast.success("Deleted")
              }
            })
        }
        else
        {
          toast.warning("Wrong Key")
          toast.error("Access Failed")
          setTimeout(()=>{
            navigate("/")
          },1500)
        }
       }
      }
    }
    function generatePDF()
    {
     let table= document.getElementById('tables')
     const report = new JsPDF('landscape','px',[1150,885]);
     report.html(table).then(() => {
     report.save('Contacts.pdf');})
    }
  return (
    <div>
      <ToastContainer  position='top-center' />
      <main className="ms-main ms-main-responsive">
  <div className="ms-page-content">
    {/*================= Banner Area Start =================*/}
    <section className="ms-hero services">
      <div className="ms-parallax jarallax-img" data-speed="0.7" data-type="scroll">
        <div className="ms-hc">
          <div className="ms-hc--inner">
          <p className="ms-hero-subtitle text-dark">Contacts.</p><br />
            <h1 style={{fontSize:"30px",textTransform:"capitalize"}} className="ms-hero-title"> <span className='text-dark'>Here are your Contacts Details of Clients.</span></h1>
          </div>
        </div>
      </div>
    </section>
    {/*================= Banner Area End =================*/}
    {/*================= Services Area Start =================*/}
    <div className="services-area-2 services">
      <div className="container tables">
        <div className="services-area-inner">
          <div className="services-bottom">
            <div className="services-item">
              <div className="row">
               {
                Condition?
                <EditContact fun={NotEdit} Data={Obj[Key]} id={Key}/>
               :
              //  <DataTable columns={Columns} data={Obj?Object.keys(Obj):""}/>
               <div className='container'>
                <div className="row">
                <div className="col-md-7 col-sm-12 col-xs-12 mb-2">
                <input type="text" value={Search} className="form-control" onChange={search} placeholder='Search by Name,Phone Number or Email'/>
                </div>
                <div style={{display:"inline-flex"}} className="col-md-5 col-sm-12 col-xs-12 mb-2">
                  <button className='btn btn-danger' onClick={Deletee}>Delete</button>
                  <ReactHTMLTableToExcel className="btn btn-success" table="table-to-xls" filename="Contacts" sheet="Contacts" buttonText="Export Data to Excel"/>
                  <button className='btn btn-primary' onClick={generatePDF}>Download PDF</button>
                </div>
                </div>
                <br />
               <div id='tables'>
               <table id='table-to-xls' className='table table-bordered table-striped table-hover'>
                <thead className='text-white text-center'>
                    <tr>
                        <th>S.No</th>
                        <th>Name</th>
                        <th>Phone Number</th>
                        <th>Email</th>
                        <th>Message</th>
                        <th>Date</th>
                        <th>Operation</th>
                    </tr>
                </thead>
                <tbody className='text-white text-center'>
                    {
                        Obj?Object.keys(Obj).reverse().map(function(key,index)
                        {
                            return(
                                <tr className='text-white' key={index}>
                                <td>{index+1}</td>
                                <td>{Obj[key].Name}</td>
                                <td>{Obj[key].Phone}</td>
                                <td>{Obj[key].Email}</td>
                                <td>{Obj[key].Message}</td>
                                <td>{Obj[key].Date}</td>
                                <td><div className="text-center">
                                    {/* <button onClick={()=>Edit(key)} className="btn btn-primary" defaultValue="Edit" >Edit</button> */}
                                    <button onClick={()=>Delete(key)} className="btn btn-danger" defaultValue="Delete" >Delete</button>
                                    </div>
                                </td>
                                </tr>
                            )
                        }):(
                            <tr style={{fontSize:"30px"}} className='text-white text-center'>
                                <td colSpan={7}>No Record Found</td>
                            </tr>
                        )
                    }
                </tbody>
                </table>
               </div>
               </div>
               }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/*================= Services Area End =================*/}
  </div>
</main>
    </div>
  )
}
export default ContactDetail