import React from 'react'
import { BrowserRouter, Route, Routes} from 'react-router-dom'
import Gallery from './Pages/Gallery'
import AboutMe from './Pages/AboutMe'
import ContactUs from './Pages/ContactUs'
import Blog from './Pages/Blog'
import AdminLogin from './Pages/AdminLogin'
import Page404 from './Pages/Page404'
import Services from './Pages/Services'
import Contact from './AdminPages/Contact'
import AddAbout from './AdminPages/AddAbout'
import Settings from './AdminPages/Settings'
import HomeFour from './Pages/HomeFour'
import LifeStyle from './Pages/LifeStyle'
import Prints from './Pages/Prints'
import Pulse from './Pages/Pulse'
import Portraits from './Pages/Portraits'

const App = () => {
  return (
    <div>
      <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomeFour/>} ></Route>
        <Route path='/Gallery' element={<Gallery/>} ></Route>
        <Route path='/LifeStylePortraits' element={<LifeStyle/>} ></Route>
        <Route path='/Prints' element={<Prints/>} ></Route>
        <Route path='/PulseofAfterHour' element={<Pulse/>} ></Route>
        <Route path='/PortraitGallery' element={<Portraits/>} ></Route>
        <Route path='/AboutMe' element={<AboutMe/>} ></Route>
        <Route path='/Blog' element={<Blog/>} ></Route>
        <Route path='/Services' element={<Services/>} ></Route>
        <Route path='/ContactUs' element={<ContactUs/>} ></Route>
        <Route path='*' element={<Page404/>} ></Route>
        <Route path='/AdminLogin' element={<AdminLogin/>} ></Route>
        <Route path='/Contact' element={<Contact/>} ></Route>
        <Route path='/AddAbout' element={<AddAbout/>} ></Route>
        <Route path='/Settings' element={<Settings/>} ></Route>
      </Routes>
      </BrowserRouter>
    </div>
  )
}
export default App