import React, { useEffect, useRef, useState } from 'react'
import Firebase from '../Firebase'

const EditContact = (props) => {
    const [Obj1,SetObj1]=useState({})
    const[Error,SetError]=useState(false)
    const phn=useRef()
    const name=useRef()
    const email=useRef()
    const Name=useRef()
    const Phone=useRef()
    const Email=useRef()
    const Message=useRef()
    useEffect(function()
    {
        if(props.Data)
        {
            SetObj1(props.Data)
            Name.current.value=props.Data.Name
            Phone.current.value=props.Data.Phone
            Email.current.value=props.Data.Email
            Message.current.value=props.Data.Message
        }
        else
        {
            props.fun()
        }
    },[])
    function set(event)
    {
      SetObj1({...Obj1,[event.target.name]:event.target.value.trim()})
    }
    function Send(e)
    {
      e.preventDefault()
      if(Obj1.Name!="" && Obj1.Phone!="" && Obj1.Email!="" && Obj1.Message!="")
      {
          if(Error==false)
          {
              Firebase.child(`Contacts/${props.id}`).update(Obj1,err=>{
                  if(err)
                  {
                      alert("Some Error Occured")
                  }
                  else
                  {
                      alert("Contact Updated.")
                      props.fun()
                  }
              })
          }
          else
          {
              alert("Enter a valid Phone Number")
          }
      }
      else
      {
          alert("Field is Empty")
      }
    }
    function check(e)
    {
      if(e.target.value=="")
      {
          SetError(true)
          SetObj1({...Obj1,"Phone":""})
          phn.current.innerHTML="Enter Phone Number"
          phn.current.style.color="orange"
          phn.current.style.marginTop="5px"
      }
      else
      {
          if(e.target.value.length==10)
          {
              SetError(false)
              SetObj1({...Obj1,"Phone":e.target.value.trim()})
              phn.current.innerHTML="Phone Number is perfect"
              phn.current.style.color="green"
              phn.current.style.marginTop="5px"
          }
          else
          {
              SetError(true)
              SetObj1({...Obj1,"Phone":""})
              phn.current.innerHTML="Enter a valid Phone Number"
              phn.current.style.color="red"
              phn.current.style.marginTop="5px"
          }
      }
    } 
    function validateName(event) {
        if(event.target.value=="")
        {
         SetObj1({...Obj1,"Name":""})
           name.current.innerHTML="Enter your Name"
           name.current.style.color="orange"
           name.current.style.marginTop="5px"
        }
        else
        {
         if (!/^[a-zA-Z\s]+$/.test(event.target.value))
         {
           SetObj1({...Obj1,"Name":""})
           name.current.innerHTML="Invalid Name. Enter a correct one"
           name.current.style.color="red"
           name.current.style.marginTop="5px"
         }
         else
         {
         if (event.target.value.trim().length < 3) 
         {
           SetObj1({...Obj1,"Name":""})
           name.current.innerHTML="Enter your Full Name"
           name.current.style.color="orange"
           name.current.style.marginTop="5px"
         }
         else
         {
           SetObj1({...Obj1,"Name":event.target.value.trim()})
           name.current.innerHTML="Your Name is Perfect"
           name.current.style.color="Green"
           name.current.style.marginTop="5px"
         }
         }
        }
       }
       function validateEmail(event){
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
         if(event.target.value=="")
         {
             SetObj1({...Obj1,"Email":""})
             email.current.innerHTML="Enter Email Address."
             email.current.style.color="orange"
             email.current.style.marginTop="5px"
         }
         else
         {
         if(regex.test(event.target.value))
         {
             SetObj1({...Obj1,"Email":event.target.value.trim()})
             email.current.innerHTML="Your Email Address is valid."
             email.current.style.color="green"
             email.current.style.marginTop="5px"
         }
         else
         {
             SetObj1({...Obj1,"Email":""})
             email.current.innerHTML="Enter a valid Email Address."
             email.current.style.color="red"
             email.current.style.marginTop="5px"
         }
         }
       }
  return (
    <div>
      <div className="contact-area contact">
                <div className="container">
                    <div className="contact-area-inner">
                        <div className="row">
                            <div className='container'>
                                <div className="row">
                            <div className="col-lg-12">
                                <div className="contact-form">
                                    <form aria-label="Contact form">
                                        <div className="row">
                                            <div className="form-group col-md-6 col-sm-12">
                                                <input ref={Name} onChange={validateName} className='text-white' placeholder="Enter your Name"  type="text" name="Name" required />
                                            <p ref={name}></p>
                                            </div>
                                            <div className="form-group col-md-6 col-sm-12">
                                                <input ref={Phone} placeholder="Enter your Phone Number" className='text-white' type="number" onChange={check} max={10} min={10} name="Phone" required />
                                                <p ref={phn} ></p>
                                            </div>
                                            <div className="form-group col-12">
                                                <input ref={Email} onChange={validateEmail} className='text-white' placeholder="Enter your Email" type="email" name="Email" required />
                                            <p ref={email}></p>
                                            </div>
                                            <div className="form-group col-12">
                                                <textarea ref={Message} onChange={set} className='text-white' placeholder="Enter your Message" name="Message"/>
                                            </div>
                                            <div className="ms-cf--bottom">
                                                <button className="btn btn-primary" onClick={Send} type="submit" >Submit</button>
                                                <button className="btn btn-danger" onClick={props.fun} type="button" >Cancel</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}
export default EditContact