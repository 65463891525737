import React, { useEffect, useRef, useState } from 'react'
import Firebase from '../Firebase'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const AdminLoginComponent = () => {
  const [Obj,SetObj]=useState({
    Username:"",
    Password:""
  })
  const[Obj1,SetObj1]=useState({})
  const Pass=useRef()
  const navigate=useNavigate()
  useEffect(function()
  {
      Firebase.child("AdminBio").on("value",function(snap)
      {
          if(snap.val()==null)
          {
              SetObj1({})
          }   
          else
          {
              SetObj1(snap.val())
          }
      }) 
  },[])
  function set(event)
  {
    SetObj({...Obj,[event.target.name]:event.target.value})
  }
  function Login(e)
  {
    e.preventDefault()
    if(Obj.Username!="" && Obj.Password!="")
    {
      Firebase.child("UserDetails").on("value",function(snap)
      {
        if(snap.val()==null)
        {
          toast.error("No Details Found")
        }
        else
        {
          if(snap.val().Username==Obj.Username && Obj.Password==snap.val().Password)
          {
            toast.success("Login")
            navigate("/AddAbout")
          }
          else
          {
            toast.error("Wrong Username and Password")
          }
        }
      })
    }
    else
    {
      toast.warning("Field is Empty")
    }
  }
  function Check(e)
  {
    if(e.target.checked==true)
    {
      Pass.current.type="text"
    }
    else
    {
      Pass.current.type="password"
    }
  }
  return (
    <div>
      <ToastContainer  position='top-center' />
<main className="ms-main ms-main-responsive ">
  <div className="ms-page-content">
    {/*================= Contact Area Start =================*/}
    <div className="contact-area contact account">
      <div className="container">
        <h1 className="heading-title">My account</h1>
        <h2 className="sub-title">Login</h2>
        <div className="contact-area-inner">
          <div className="row">
            <div className="col-lg-6">
              <div className="contact-form">
                <form aria-label="Contact form">
                  <div className="row">
                    <div className="form-group col-12">
                      <label>Username&nbsp;<span className="required">*</span></label>
                      <input type="text" name="Username" className='text-white' onChange={set} placeholder='Enter your Username' required />
                    </div>
                    <div className="form-group col-12">
                      <label>Password&nbsp;<span className="required">*</span></label>
                      <input ref={Pass} type='password' className='text-white' onChange={set} placeholder='Enter the password' name="Password" required />
                    </div>
                    <label className="woocommerce-form__label woocommerce-form__label-for-checkbox woocommerce-form-login__rememberme checkbox">
                      <input className="woocommerce-form__input woocommerce-form__input-checkbox" onClick={Check} name="rememberme" type="checkbox" id="rememberme" defaultValue="forever" /> <span>Show Password</span>
                    </label>
                    <div className="ms-cf--bottom col-12">
                      <input className="btn btn--primary" onClick={Login} type="submit" defaultValue="Log in" />
                    </div>
                    {/* <p className="woocommerce-LostPassword lost_password">
                      <a href="#0">Lost your password?</a>
                    </p> */}
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6">
            <div className="image-left-side">
            <img className='image' src={Obj1.ProfileImage?Obj1.ProfileImage:"assets/images/portfolio/personal-cv.jpg"} alt="image" />
           </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/*================= Contact Area End =================*/}
    {/*================= Services Area Start =================*/}
    {/*================= Services Area End =================*/}
  </div>
</main>
    </div>
  )
}
export default AdminLoginComponent