import React, { useEffect, useState } from 'react'
import AddAboutDetails from '../AdminComponents/AddAboutDetails'
import AdminHeader from '../AdminComponents/AdminHeader'
import Footer from '../Components/Footer'
import Firebase from '../Firebase'
import AboutMeComponent from '../AdminComponents/AboutMeComponent'

const AddAbout = () => {
    const[Condition,SetCondition]=useState(true)
    useEffect(function()
    {
        Firebase.child("AdminBio").on("value",function(snap)
        {
            if(snap.val()==null)
            {
                SetCondition(false)
            }
            else
            {
                SetCondition(true)
            }
        })
    },[])
  return (
    <div>
        <AdminHeader/>
        <main className="ms-main ms-main-responsive ">
      {
        Condition?<AboutMeComponent/>:<AddAboutDetails/>
      }
      </main>
      <Footer/>
    </div>
  )
}

export default AddAbout