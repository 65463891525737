import React, { useEffect, useRef, useState } from 'react'
import Firebase from '../Firebase'
import "./Style.css"
const SettingsComponent = () => {
    const [Obj,SetObj]=useState({
        Image:""
    })
    const [Obj1,SetObj1]=useState({
        Username:"",
        Password:"",
    })
    const[Obj2,SetObj2]=useState({})
    const[Logo,SetLogo]=useState({})
    const[Condition,SetCondition]=useState(false)
    const[Condition1,SetCondition1]=useState(false)
    const[Condition2,SetCondition2]=useState(false)
    const image=useRef()
    const Face=useRef()
    const Insta=useRef()
    const Twit=useRef()
    useEffect(function()
    {
        Firebase.child("Settings/Logo").on("value",function(snap)
        {
            if(snap.val()==null)
            {
                SetLogo({})
            }
            else
            {
                SetLogo(snap.val())
            }
        })
        Firebase.child("Settings/SocialMedia").on("value",function(snap)
        {
            if(snap.val()==null)
            {
                SetObj2({})
                SetCondition2(true)
                Face.current.value=""
                Insta.current.value=""
                Twit.current.value=""
            }
            else
            {
                SetObj2(snap.val())
                SetCondition2(false)
            }
        })
    },[])
    useEffect(function()
    {
      if(Condition2==true)
      {
        Face.current.value=Obj2.Facebook?Obj2.Facebook:""
        Insta.current.value=Obj2.Instagram?Obj2.Instagram:""
        Twit.current.value=Obj2.Twitter?Obj2.Twitter:""
      }
    },[Condition2])
    function UploadLogo()
    {
        image.current.click()
    }
    function Upload(event)
    {
        const file=event.target.files[0]
        if(file)
        {
            const ext= file.type.split("/")
            if(ext[0]=="image")
            {
               if(ext[1]=="jpg" || ext[1]=="png" || ext[1]=="jpeg" || ext[1]=="PNG")
               {
                const reader=new FileReader()
                reader.readAsDataURL(file)
                reader.onload=function()
                {
                    SetObj({...Obj,"Image":reader.result})
                }
                }
               else
               {
                   alert("Only jpeg,jpg,png images are allowed")
               }
            }
            else
            {
               alert("Only Images are allowed to upload")
            }
        }
    }
    function Save(e)
    {
        e.preventDefault()
        if(Obj.Image!="")
        {
            Firebase.child("Settings/Logo").update(Obj,err=>{
                if(err)
                {
                    alert("Error Occured")
                }
                else
                {
                    alert("Logo updated")
                    SetCondition(false)
                }
            })
        }
        else
        {
            alert("Upload your Logo first")
        }
    }
    function set(event)
    {
        SetObj1({...Obj1,[event.target.name]:event.target.value})
    }
    function Submit(e)
    {
        e.preventDefault()
        if(Obj1.Username!="" && Obj1.Password!="")
        {
            Firebase.child("UserDetails").update(Obj1,err=>{
                if(err)
                {
                    alert("Error Occured.")
                }
                else
                {
                    alert("Your Username and Password Updated.")
                    SetCondition1(false)
                }
            })
        }
        else
        {
            alert("Field is Empty")
        }
    }
    function Editlink()
    {
      SetCondition2(true)
    }
    function set1(event)
    {
        SetObj2({...Obj2,[event.target.name]:event.target.value})
    }
    function Submit1(e)
    {
      e.preventDefault()
      if(Obj2.Facebook!="" && Obj2.Twitter!="" && Obj2.Instagram!="")
      {
        Firebase.child("Settings/SocialMedia").update(Obj2,err=>{
          if(err)
          {
            alert("Error Occured")
          }
          else
          {
            alert("Social Media Updated.")
            SetCondition2(false)
          }
        })
      }
      else
      {
        alert("Field is Empty")
      }
    }
    return (
    <div>
      <main className="ms-main ms-main-responsive">
  <div className="ms-page-content">
    <section className="ms-hero services">
      <div className="ms-parallax jarallax-img" data-speed="0.7" data-type="scroll">
        <div className="ms-hc">
          <div className="ms-hc--inner">
            <p className="ms-hero-subtitle text-dark">Settings</p><br />
            <h1 className="ms-hero-title" style={{fontSize:"30px",textTransform:"capitalize"}} ><span className='text-dark'>Here you can change your Logo, Username & Password and Social Media Links.</span></h1>
          </div>
        </div>
      </div>
    </section>
    <div className="services-area-2 services">
      <div className="container">
        <div className="services-area-inner">
          <div className="services-bottom">
            <div className="services-item">
              <div className="row">
                <div className="col-lg-4 col-md-6 text-center">
                  {
                    Condition?<div className="ms-sb img-top">
                    <div className="ms-sb--img boxed">
                      <img src={Obj.Image?Obj.Image:Logo.Image?Logo.Image:"assets/images/logo/logo-dark.png"} className="attachment-full size-full imagee" alt="image" />
                    </div>
                    <div className="ms-sb--inner">
                      <h3 className="ms-sb--title">
                        <span>Change your Logo</span>
                      </h3>
                      <p className="ms-sb--text">
                        <br />
                      <div className="ms-cf--bottom">
                        <input type="file" ref={image} onChange={Upload} hidden />
                        <button className='btn btn-danger' type='button' onClick={()=>SetCondition(false)}>Cancel</button>
                       <button className='btn btn-primary' onClick={UploadLogo}>Change</button>
                       <button className='btn btn-success' onClick={Save}>Save</button>
                        </div>
                      </p>
                    </div>
                  </div>:<div className="ms-sb img-top">
                    <div className="ms-sb--img boxed">
                      <img src={Logo.Image?Logo.Image:"assets/images/logo/logo-dark.png"} className="attachment-full size-full imagee" alt="image" />
                    </div>
                    <div className="ms-sb--inner">
                      <h3 className="ms-sb--title">
                        <br />
                        <span>Your Logo</span>
                      </h3>
                      <p className="ms-sb--text">
                      <div className="ms-cf--bottom">
                        <input style={{margin:"10px"}} className="btn btn-primary" onClick={()=>SetCondition(true)} type="Edit" defaultValue="Edit your Logo" />
                        {/* <input style={{margin:"10px",width:"30%"}} className="btn btn-danger" type="Delete" defaultValue="Delete" /> */}
                        </div>
                      </p>
                    </div>
                  </div>
                  }
                </div>
                <div className="col-lg-4 col-md-6">
                {
                    Condition1?<div className="ms-sb img-top">
                    <div className="ms-sb--inner">
                      <h3 className="ms-sb--title text-center">
                        <span> Username & Password</span>
                      </h3>
                      <br />
                      <div className="container divcontainers">
                      <form>
                        <label className='labelt'>Username:</label>
                        <input type="text" onChange={set} name='Username' className="form-control" placeholder='Enter your Username' />
                        <label className='labelt'>Password:</label>
                        <input type="password" onChange={set} name='Password' className="form-control" placeholder='Enter your Password' />
                      </form>                    
                      </div>
                      <p className="ms-sb--text">
                      <div className="ms-cf--bottom text-center">
                      <button className="btn btn-danger" onClick={()=>SetCondition1(false)} type="cancel" >Cancel</button>
                        <button style={{margin:"15px"}} className="btn btn-primary" type="Submit" onClick={Submit}>Submit</button>
                        </div>
                      </p>
                    </div>
                  </div>:<div className="ms-sb img-top">
                    <div className="ms-sb--inner">
                      <h3 className="ms-sb--title text-center">
                        <span>Change your Login <br /><br /> Username & Password</span>
                      </h3>
                      <br />
                      <div className="container text-center">
                      <form>
                        <label className="form-control label">Username</label>
                        <label className="form-control">Password</label>
                      </form>
                      </div>
                      <br />
                      <p className="ms-sb--text">
                      <div className="ms-cf--bottom text-center">
                      <input style={{margin:"10px"}} className="btn btn-primary" onClick={()=>SetCondition1(true)} type="Change" defaultValue="Change" />
                        </div>
                      </p>
                    </div>
                  </div>
                }
                </div>
                <div className="col-lg-4 col-md-6">
                 {
                    Condition2?<div className="ms-sb img-top">
                    <div className="ms-sb--inner">
                      <h3 className="ms-sb--title text-center">
                        <span> Social Media Handle</span>
                      </h3>
                      <div className="container">
                      <form>
                        <label>Facebook:</label>
                        <input ref={Face} type="url" onChange={set1} className="form-control" name='Facebook' placeholder='Enter your Facebook Link' />
                        <label>Instagram:</label>
                        <input ref={Insta} type="url" onChange={set1} className="form-control" name='Instagram' placeholder='Enter your Instagram Link' />
                        <label>Linkedin:</label>
                        <input ref={Twit} type="url" onChange={set1} className="form-control" name='Twitter' placeholder='Enter your Linkedin Link' />
                      </form>                    
                      </div>
                      <p className="ms-sb--text">
                      <div className="ms-cf--bottom text-center">
                      <button className="btn btn-danger" onClick={()=>SetCondition2(false)}  type="cancel" >Cancel</button>
                      <button style={{margin:"5px"}} className="btn btn-primary" onClick={Submit1} type="Submit" >Submit</button>
                        </div>
                      </p>
                    </div>
                  </div>:
                    <div className="ms-sb img-top">
                    <div className="ms-sb--inner">
                      <h3 className="ms-sb--title text-center">
                        <span> Social Media Handle</span>
                      </h3>
                      <br />
                      <div className="container divcontainer text-center">
                      <form>
                        <label className='form-control'>Facebook:</label>
                        <label className='form-control'>Instagram:</label>
                        <label className='form-control'>Linkedin:</label>
                      </form>                    
                      </div>
                      <p className="ms-sb--text">
                      <div className="ms-cf--bottom text-center">
                      <button style={{margin:"5px"}} className="btn btn-primary" type="Submit" onClick={Editlink}>Edit </button>
                        </div>
                      </p>
                    </div>
                  </div>
                 }
                </div>
                {/* <div className="col-lg-4 col-md-6 text-center">
                  <div className="ms-sb img-top">
                    <div className="ms-sb--img boxed four">
                      <img src="assets/images/services/icon/code-3.svg" className="attachment-full size-full" alt="image" />
                    </div>
                    <div className="ms-sb--inner">
                      <h3 className="ms-sb--title">
                        <span>Motion Graphic</span>
                      </h3>
                      <p className="ms-sb--text">Create a platform with the best and coolest
                        quality from us.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 text-center">
                  <div className="ms-sb img-top">
                    <div className="ms-sb--img boxed five">
                      <img src="assets/images/services/icon/code-4.svg" className="attachment-full size-full" alt="image" />
                    </div>
                    <div className="ms-sb--inner">
                      <h3 className="ms-sb--title">
                        <span>Photography</span>
                      </h3>
                      <p className="ms-sb--text">Create a platform with the best and coolest
                        quality from us.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 text-center">
                  <div className="ms-sb img-top">
                    <div className="ms-sb--img boxed six">
                      <img src="assets/images/services/icon/code-6.svg" className="attachment-full size-full" alt="image" />
                    </div>
                    <div className="ms-sb--inner">
                      <h3 className="ms-sb--title">
                        <span>Videography</span>
                      </h3>
                      <p className="ms-sb--text">Create a platform with the best and coolest
                        quality from us.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 text-center">
                  <div className="ms-sb img-top">
                    <div className="ms-sb--img boxed one">
                      <img src="assets/images/services/icon/code-1.svg" className="attachment-full size-full" alt="image" />
                    </div>
                    <div className="ms-sb--inner">
                      <h3 className="ms-sb--title">
                        <span>Development</span>
                      </h3>
                      <p className="ms-sb--text">Create a platform with the best and coolest
                        quality from us.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 text-center">
                  <div className="ms-sb img-top">
                    <div className="ms-sb--img boxed two">
                      <img src="assets/images/services/icon/code-2.svg" className="attachment-full size-full" alt="image" />
                    </div>
                    <div className="ms-sb--inner">
                      <h3 className="ms-sb--title">
                        <span>UI/UX Designer</span>
                      </h3>
                      <p className="ms-sb--text">Create a platform with the best and coolest
                        quality from us.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 text-center">
                  <div className="ms-sb img-top">
                    <div className="ms-sb--img boxed three">
                      <img src="assets/images/services/icon/code-5.svg" className="attachment-full size-full" alt="image" />
                    </div>
                    <div className="ms-sb--inner">
                      <h3 className="ms-sb--title">
                        <span>Graphics Designer</span>
                      </h3>
                      <p className="ms-sb--text">Create a platform with the best and coolest
                        quality from us.</p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
    </div>
  )
}
export default SettingsComponent