import React from 'react'
const GalleryBackgroundBanner = (props) => {
    return (
        <div className='ms-main home-six list'  >
            <div className="banner-area-2">
                <main className="ms-main-responsive">
                    <div className="ms-page-content">
                        {/*================= Banner Area Start =================*/}
                        <section className="ms-hero services">
                            <div className="ms-parallax jarallax-img" data-speed="0.7" data-type="scroll">
                                <div className="ms-hc">
                                    <div className="ms-hc--inner">
                                        <p className="ms-hero-subtitle make-up text-dark">{props.title?props.title:"Gallery"}</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        </div>
                </main>
                {/* <div className="ms-tt-wrap">
                    <ul className="ms-tt">
                        <li className="ms-tt__text">EasyCapture: <span>Streamlined </span> Photography for <span>Effortless Memories.</span>&nbsp;</li>
                        <li className="ms-tt__text">EasyCapture: <span>Streamlined </span> Photography for <span>Effortless Memories.</span>&nbsp;</li>
                        <li className="ms-tt__text">EasyCapture: <span>Streamlined </span> Photography for <span>Effortless Memories.</span>&nbsp;</li>
                        <li className="ms-tt__text">EasyCapture: <span>Streamlined </span> Photography for <span>Effortless Memories.</span>&nbsp;</li>
                        <li className="ms-tt__text">EasyCapture: <span>Streamlined </span> Photography for <span>Effortless Memories.</span>&nbsp;</li>
                        <li className="ms-tt__text">EasyCapture: <span>Streamlined </span> Photography for <span>Effortless Memories.</span>&nbsp;</li>
                        <li className="ms-tt__text">EasyCapture: <span>Streamlined </span> Photography for <span>Effortless Memories.</span>&nbsp;</li>
                        <li className="ms-tt__text">EasyCapture: <span>Streamlined </span> Photography for <span>Effortless Memories.</span>&nbsp;</li>
                    </ul>
                </div> */}
            </div>
        </div>
    )
}

export default GalleryBackgroundBanner