import React from 'react'
import Page404Componenet from '../Components/Page404Componenet'

const Page404 = () => {
  return (
    <div>
        <Page404Componenet/>
    </div>
  )
}

export default Page404