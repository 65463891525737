import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
const container=document.getElementById('root')
const root = createRoot(container)
const redirectToHomeOnReload = () => {
    if (window.performance) {
      if (performance.navigation.type === 1) {
        // Page is reloaded
        window.location.href = '/';
      }
    }
  }
root.render(<App/>)
redirectToHomeOnReload()