import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Firebase from '../Firebase'

const AdminHeader = () => {
    const [Togglebtn, setTogglebtn] = useState(false)
    const[Logo,SetLogo]=useState({})
    const navigate=useNavigate()
    const[Obj,SetObj]=useState({})
    useEffect(function()
    {
      Firebase.child("Settings/Logo").on("value",function(snap)
      {
        if(snap.val()==null)
        {
          SetLogo({})
        }
        else
        {
          SetLogo(snap.val())
        }
      })
      Firebase.child("Settings/SocialMedia").on("value",function(snap)
      {
        if(snap.val()==null)
        {
          SetObj({})
        }
        else
        {
          SetObj(snap.val())
        }
      })
    },[])
    function Logout()
    {
      window.location.reload()
    setTimeout(function()
    {
      navigate("/")
    },250)
    }
  return (
    <div>
    {/*================= Header One Start =================*/}
    {/*================= Sticky Menu Start Here =================*/}
    <div className="main-header js-main-header auto-hide-header full-width menu-center header--sticky" data-blur="off">
      <div className="main-header__layout">
        <div className="main-header__inner">
          <div className="main-header__logo">
            <div className="logo-dark">
              <Link to="/">
                <img src={Logo.Image?Logo.Image:"assets/images/logo/logo-dark.png"} alt="Most" />
              </Link>
            </div>
            <div className="logo-light">
              <Link to="/">
                <img src={Logo.Image?Logo.Image:"assets/images/logo/logo-dark.png"} alt="Most" />
              </Link>
            </div>
          </div>
          <nav className={Togglebtn ? "main-header__nav js-main-header__nav main-header__default is_mobile main-header__nav--is-visible" : "main-header__nav js-main-header__nav main-header__default is_mobile"} id="main-header-nav" aria-labelledby="primary-menu">
            <ul id="primary-menu" className="navbar-nav">
              {/* <li className="menu-item menu-item-has-children">
                <Link  title="Home"><span>Home</span></Link>
                <ul className="sub-menu">
                  <li className="menu-item">
                    <Link to={'/'}  >Home</Link>
                  </li>
                  <li className="menu-item">
                    <Link to={'/HomeTwo'} >Home Portfolio</Link>
                  </li>
                  <li className="menu-item">
                    <Link to={'/HomeThree'} >Full Page Slider</Link>
                  </li>
                </ul>
              </li> */}
              {/* <li className="menu-item ">
                <Link to={'/AddGallery'}  title="Gallery"><span>Gallery</span></Link>
                
              </li> */}
              <li className="menu-item ">
                <Link to={'/AddAbout'} title="About Me"><span>About Me</span></Link>
                
              </li>
              {/* <li className="menu-item menu-item-has-children">
                <Link to={'/AboutUs'} title="About Us"><span>About Us</span></Link>
              </li> */}
              {/* <li className="menu-item">
                <Link to={'/Blog'} title="Blog"><span>Blog</span></Link>
              </li> */}
              <li className="menu-item">
                <Link to={'/Contact'} title="Contacts"><span>Contacts</span></Link>
              </li>
              <li className="menu-item">
                <Link to={'/Settings'} title="Settings"><span>Settings</span></Link>
              </li>
              <li className="menu-item">
                <a onClick={Logout} title="Logout"><span>Logout</span></a>
              </li>
              {/* <li className="menu-item ">
                <a  title=""> <img src="assets/images/SocialMedia/share.png" alt="" /></a>
              </li> */}
            </ul>
          </nav>
          
          <div className="main-header--widgets">
          <div className='header__search-icon' >
              <img src="assets/images/SocialMedia/facebook-app-symbol.png" onClick={()=>window.open(Obj?.Facebook?Obj?.Facebook:"","_blank")} alt="" />
             <img src="assets/images/SocialMedia/instagram.png" onClick={()=>window.open(Obj?.Instagram?Obj?.Instagram:"","_blank")} alt="" />
             <img src="assets/images/SocialMedia/linkedin (1).png" onClick={()=>window.open(Obj?.Twitter?Obj?.Twitter:"","_blank")} alt="" />
              </div>

            {/* <div className="ms_theme_mode">
        <div className="ms_tm--inner">
          <div className="theme-toggle" id="theme-toggle">
            <input type="checkbox" id="switcher" className="check" defaultChecked />
            <svg className="sun-and-moon" aria-hidden="true" width={24} height={24} viewBox="0 0 24 24">
              <mask className="moon" id="moon-mask">
                <rect x={0} y={0} width="100%" height="100%" fill="white" />
                <circle cx={24} cy={10} r={6} fill="black" />
              </mask>
              <circle className="sun" cx={12} cy={12} r={6} mask="url(#moon-mask)" fill="currentColor">
              </circle>
              <g className="sun-beams" stroke="currentColor">
                <line x1={12} y1={1} x2={12} y2={3} />
                <line x1={12} y1={21} x2={12} y2={23} />
                <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />
                <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />
                <line x1={1} y1={12} x2={3} y2={12} />
                <line x1={21} y1={12} x2={23} y2={12} />
                <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />
                <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
              </g>
            </svg>
          </div>
        </div>
      </div> */}
            {/* <div className="header__search-icon">
        <svg viewBox="0 0 24 24" onClick={()=>setToggleSearch(!ToggleSearch)} xmlns="http://www.w3.org/2000/svg">
          <path d="M16.57,16.15A9,9,0,1,0,15,17.46h0l6.25,6.25,1.42-1.42Zm-3-.14a7.07,7.07,0,1,1,1.56-1.28A6.88,6.88,0,0,1,13.59,16Z">
          </path>
        </svg>
      </div> */}
            {/* <div className={ToggleSearch?"header__search-modal modal--is-visible":"header__search-modal"}>
        <button className="header__search--close-btn"  >
          <svg className="icon icon--sm" oncli  >
            <title>Close modal window</title>
            <g fill="none" stroke="currentColor" strokeMiterlimit={10} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
              <line x1={3} y1={3} x2={21} y2={21} />
              <line x1={21} y1={3} x2={3} y2={21} />
            </g>
          </svg>
        </button>
        <div className="header__search--inner">
          <form role="search" method="get" className="searchform" action="https://theme.madsparrow.me/most/">
            <div className="ms-search-widget">
              <input type="search" placeholder="Search..." defaultValue name="s" className="search-field" />
              <div className="ms-search--btn">
                <span className="ms-sb--icon">
                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.57,16.15A9,9,0,1,0,15,17.46h0l6.25,6.25,1.42-1.42Zm-3-.14a7.07,7.07,0,1,1,1.56-1.28A6.88,6.88,0,0,1,13.59,16Z">
                    </path>
                  </svg>
                </span>
                <input type="submit" className="search-submit" defaultValue />
              </div>
            </div>
          </form>
        </div>
      </div> */}
          </div>

          <button onClick={() => setTogglebtn(!Togglebtn)} className="main-header__nav-trigger js-main-header__nav-trigger menu-default" aria-label="Toggle menu" aria-expanded={Togglebtn ? "true" : "false"} aria-controls="main-header-nav">
            <span>Menu</span>
            <i className="main-header__nav-trigger-icon" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
    {/*================= Header End Here =================*/}
    {/*================= Header One End =================*/}
    {/*================= Mobile Menu Start Here =================*/}
    {/*================= Mobile Menu Start Here =================*/}
  </div>
  )
}
export default AdminHeader