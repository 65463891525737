import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Firebase from '../Firebase'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactUsContent = () => {
    const [Obj,SetObj]=useState({
        Name:"",
        Phone:"",
        Email:"",
        Message:""
      })
      const[Obj1,SetObj1]=useState({})
      const[Error,SetError]=useState(true)
      const navigate=useNavigate()
      const d=new Date()
      const date=`${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()}`
      useEffect(function()
      {
        Firebase.child("AdminBio").on("value",function(snap)
        {
            if(snap.val()==null)
            {
                SetObj1({})
            }
            else
            {
                SetObj1(snap.val())
            }
        })
      },[])
      function set(event)
      {
        SetObj({...Obj,[event.target.name]:event.target.value.toLowerCase(),"Date":date})
      }
      function EmailChange(email){
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email)
      }
      function NameChange(event){
        const name = event.target.value.replace(/[^a-zA-Z\s]/g, '');
        SetObj({...Obj,"Name":name});
      }
      function PhoneNumberChange(event)
      {
        const phone = event.target.value.trim().replace(/[^\d-]/g, '');
        SetObj({...Obj,"Phone":phone});
      }
    function Submit(e)
    {
        e.preventDefault()
        if(Obj.Name!="" && Obj.Phone!="" && Obj.Email!="" && Obj.Message!="")
        {
           const response= EmailChange(Obj.Email)
           if(response)
           {
            SetError(true)
            Firebase.child("Contacts").push(Obj,err=>{
            if(err)
            {
                alert("Some Error Occured")
            }
            else
            {
                toast.success("Thank you for contacting us. We will contact you soon.")
                setTimeout(() => {
                    navigate("/")
                }, 5000);
            }
            })
           }
           else
           {
            SetError(false)
            toast.error("Please enter a valid email address")
           }
        }
        else
        {
            toast.warning("Field is Empty")
        }
    }
    return (
        <div>
             <ToastContainer  position='top-center' />
            <main className="ms-main ms-main-responsive ">
                <div className="ms-page-content">
                    {/*================= Banner Area Start =================*/}
                    {/* <div className="contact-map-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="contact-map-area-fluid">
                                    <iframe className="contact-map" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2480.5411993733483!2d-0.2789787233768809!3d51.55831147182491!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNTHCsDMzJzI5LjkiTiAwwrAxNiczNS4xIlc!5e0!3m2!1sen!2sin!4v1711299268968!5m2!1sen!2sin" loading="lazy" ></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/*================= Banner Area End =================*/}
                    {/*================= Contact Area Start =================*/}
                    <div className="contact-area contact">
                        <div className="container">
                            <div className="contact-area-inner">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="content">
                                            <h2 className="title">Got A Project In Mind? <br />Contact Us.</h2>
                                            <p className="desc">Got questions or ideas? Reach out anytime! We're here to help and eager to hear from you. Let's connect and make things happen together.
                                            Whether you have questions, feedback, or ideas, we're here to listen and assist. Feel free to reach out to our team anytime. Your thoughts matter to us, and we're eager to connect with you. Let's collaborate and bring your visions to life. Drop us a line and let's start the conversation!</p>
                                        </div>
                                        <div className="row contact">
                                            {/* <ul className="col-lg-4 phone">
                                                <li className="tag">Phone Numbers:</li>
                                                <li className='text-white'><a className='text-white' href={Obj1.Phone?`tel:+44${Obj1.Phone}`:""}>{Obj1.Phone?`+44 ${Obj1.Phone}`:"Phone Number"}</a></li>
                                            </ul> */}
                                            <ul className="col-lg-12 email">
                                                <li className="tag">Email:</li>
                                                <li className='text-white'><a className='text-white' href={Obj1.Email?`mailto:${Obj1.Email}`:""}>{Obj1.Email?Obj1.Email:"Email"}</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="contact-form">
                                            <form aria-label="Contact form">
                                                <div className="row">
                                                    <div className="form-group col-md-6 col-sm-12">
                                                        <input onChange={NameChange} value={Obj.Name} className='text-white' placeholder="Enter your Name"  type="text" name="Name" required />
                                                    </div>
                                                    <div className="form-group col-md-6 col-sm-12">
                                                        <input placeholder="Enter your Phone Number" value={Obj.Phone} maxLength={16} className='text-white' type="tel"  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" onChange={PhoneNumberChange} name="Phone" required />
                                                    </div>
                                                    <div className="form-group col-12">
                                                        <input onChange={set} className='text-white' placeholder="Enter your Email" type="email" name="Email" required />
                                                        {!Error && <p style={{ color: 'red' }}>Please enter a valid email address</p>}
                                                    </div>
                                                    <div className="form-group col-12">
                                                        <textarea onChange={set} className='text-white' placeholder="Enter your Message" name="Message"/>
                                                    </div>
                                                    <div className="ms-cf--bottom">
                                                        <button className="btn btn--primary" type="submit" onClick={Submit}>Submit</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*================= Contact Area End =================*/}
                    {/*================= Services Area Start =================*/}
                    {/*================= Services Area End =================*/}
                </div>
            </main>
        </div>
    )
}
export default ContactUsContent