import React, { useEffect, useState } from 'react'
import Firebase from '../Firebase'
import EditAboutDetails from './EditAboutDetails'

const AboutMeComponent = () => {
    const[Obj,SetObj]=useState({})
    const[Condition,SetCondition]=useState(false)
    useEffect(function()
    {
        Firebase.child("AdminBio").on("value",function(snap)
        {
            if(snap.val()==null)
            {
                SetObj({})
            }
            else
            {
                SetObj(snap.val())
            }
        })
    },[])
    function Delete()
    {
        const result=window.confirm("Do you want to delete?")
        if(result==true)
        {
            Firebase.child("AdminBio").remove(err=>{
                if(err)
                {
                    alert("Error Occured")
                }
                else
                {
                    alert("Your details has been deleted.")
                }
            })
        }
    }
    function NotEdit()
    {
        SetCondition(false)
    }
  return (
    <div>
        {
            Condition?<EditAboutDetails fun={NotEdit} data={Obj}/>:<main className="ms-main ms-main-responsive ">
            <div className="ms-page-content">
                <div className="container">
                    <div className="personal-cv-area">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="image-left-side">
                                    <img src={Obj.ProfileImage?Obj.ProfileImage:"assets/images/portfolio/personal-cv.jpg"} alt="image" />
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="right-side-content">
                                    <h1 className="heading-title">{Obj.Name?Obj.Name:"Name"}</h1>
                                    <p>
                                        <em><span style={{ color: '#ef5d39' }}>{Obj.Age?`${Obj.Age} years`:"Age"} &nbsp;</span>
                                            <span style={{ color: '#808080' }}> /</span>&nbsp; &nbsp;
                                            <span style={{ color: '#ef5d39' }}>{Obj.City?Obj.City:"City"}&nbsp;</span> &nbsp;
                                            <span style={{ color: '#808080' }}>/</span>&nbsp; &nbsp;
                                            <span style={{ color: '#ef5d39' }}>{Obj.Specification?Obj.Specification:"Specification"}</span>
                                        </em>
                                    </p>
                                    <p className="desc">{Obj.Bio?Obj.Bio:"Bio"}
                                    </p>
                                    <div className="services-area">
                                        <h2 className="services-title">My Services</h2>
                                        <div className="services-items">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-6">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img default">
                                                            <img src="assets/images/services/icon/code-1.svg" className="attachment-full size-full" alt="icon" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h4 className="ms-sb--title">
                                                                <span>{Obj.Service_Title_1?Obj.Service_Title_1:"Service-Title-1"}</span>
                                                            </h4>
                                                            <p className="ms-sb--text">{Obj.Service_Description_1?Obj.Service_Description_1:"Service-Description-1"}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img default">
                                                            <img src="assets/images/services/icon/code-2.svg" className="attachment-full size-full" alt="icon" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h4 className="ms-sb--title">
                                                                <span>{Obj.Service_Title_2?Obj.Service_Title_2:"Service-Title-2"}</span>
                                                            </h4>
                                                            <p className="ms-sb--text">{Obj.Service_Description_2?Obj.Service_Description_2:"Service-Description-2"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img default">
                                                            <img src="assets/images/services/icon/code-3.svg" className="attachment-full size-full" alt="icon" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h4 className="ms-sb--title">
                                                                <span>{Obj.Service_Title_3?Obj.Service_Title_3:"Service-Title-3"}</span>
                                                            </h4>
                                                            <p className="ms-sb--text">{Obj.Service_Description_3?Obj.Service_Description_3:"Service-Description-3"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6">
                                                    <div className="ms-sb img-top">
                                                        <div className="ms-sb--img default">
                                                            <img src="assets/images/services/icon/code-4.svg" className="attachment-full size-full" alt="icon" />
                                                        </div>
                                                        <div className="ms-sb--inner">
                                                            <h4 className="ms-sb--title">
                                                                <span>{Obj.Service_Title_3?Obj.Service_Title_3:"Service-Title-4"}</span>
                                                            </h4>
                                                            <p className="ms-sb--text">{Obj.Service_Description_4?Obj.Service_Description_4:"Service-Description-4"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-center ms-cf--bottom">
                                                <button className="btn btn-primary" onClick={()=>SetCondition(true)}>Edit</button>
                                                <button style={{margin:"10px"}} className="btn btn-danger" onClick={Delete}>Delete</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="pricing-area">
                                        <h2 className="pricing-title">My Pricing</h2>
                                        <div className="pricing-inner">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-sm-4 inner">
                                                    <div className="ms-pt-block">
                                                        <div className="ms-pt--price">
                                                        </div>
                                                        <div className="ms-pt--header top">
                                                            <h4 className="ms-pt--title"> Basic </h4>
                                                            <span className="currency currency--before">$</span>
                                                            <span className="price">29</span>
                                                            <span className="period">/ Hour</span>
                                                        </div>
                                                        <div className="ms-pt--content">
                                                            <ul>
                                                                <li className="active">
                                                                    <i className="icon-check">
                                                                        <svg viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M20 6L9 17l-5-5" />
                                                                        </svg>
                                                                    </i>
                                                                    <span> Consulting </span>
                                                                </li>
                                                                <li className="active">
                                                                    <i className="icon-check">
                                                                        <svg viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M20 6L9 17l-5-5" />
                                                                        </svg>
                                                                    </i>
                                                                    <span> Development </span>
                                                                </li>
                                                                <li className="no-active">
                                                                    <i className="icon-check">
                                                                        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                                                            <g>
                                                                                <path className="cls-1" d="M7 7l18 18M7 25L25 7" />
                                                                            </g>
                                                                        </svg>
                                                                    </i>
                                                                    <span> Design </span>
                                                                </li>
                                                                <li className="no-active">
                                                                    <i className="icon-check">
                                                                        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                                                            <g>
                                                                                <path className="cls-1" d="M7 7l18 18M7 25L25 7" />
                                                                            </g>
                                                                        </svg>
                                                                    </i>
                                                                    <span> 24/7 Support </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="ms-pt--footer">
                                                            <a className="btn btn--ba btn--primary" role="button" href="#">
                                                                <div className="ms-btn__text">
                                                                    <span className="text--main">Choose Plan</span>
                                                                </div>
                                                                <span className="ms-btn--ripple" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-4 inner">
                                                    <div className="ms-pt-block">
                                                        <div className="ms-pt--price">
                                                        </div>
                                                        <div className="ms-pt--header top">
                                                            <h4 className="ms-pt--title"> Advanced </h4>
                                                            <span className="currency currency--before">$</span>
                                                            <span className="price">39</span>
                                                            <span className="period">/ Hour</span>
                                                        </div>
                                                        <div className="ms-pt--content">
                                                            <ul>
                                                                <li className="active">
                                                                    <i className="icon-check">
                                                                        <svg viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M20 6L9 17l-5-5" />
                                                                        </svg>
                                                                    </i>
                                                                    <span> Consulting </span>
                                                                </li>
                                                                <li className="active">
                                                                    <i className="icon-check">
                                                                        <svg viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M20 6L9 17l-5-5" />
                                                                        </svg>
                                                                    </i>
                                                                    <span> Development </span>
                                                                </li>
                                                                <li className="no-active">
                                                                    <i className="icon-check">
                                                                        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                                                            <g>
                                                                                <path className="cls-1" d="M7 7l18 18M7 25L25 7" />
                                                                            </g>
                                                                        </svg>
                                                                    </i>
                                                                    <span> Design </span>
                                                                </li>
                                                                <li className="no-active">
                                                                    <i className="icon-check">
                                                                        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                                                            <g>
                                                                                <path className="cls-1" d="M7 7l18 18M7 25L25 7" />
                                                                            </g>
                                                                        </svg>
                                                                    </i>
                                                                    <span> 24/7 Support </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="ms-pt--footer">
                                                            <a className="btn btn--ba btn--primary" role="button" href="#">
                                                                <div className="ms-btn__text">
                                                                    <span className="text--main">Choose Plan</span>
                                                                </div>
                                                                <span className="ms-btn--ripple" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-4 inner">
                                                    <div className="ms-pt-block">
                                                        <div className="ms-pt--price">
                                                        </div>
                                                        <div className="ms-pt--header top">
                                                            <h4 className="ms-pt--title"> Premium </h4>
                                                            <span className="currency currency--before">$</span>
                                                            <span className="price">99</span>
                                                            <span className="period">/ Hour</span>
                                                        </div>
                                                        <div className="ms-pt--content">
                                                            <ul>
                                                                <li className="active">
                                                                    <i className="icon-check">
                                                                        <svg viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M20 6L9 17l-5-5" />
                                                                        </svg>
                                                                    </i>
                                                                    <span> Consulting </span>
                                                                </li>
                                                                <li className="active">
                                                                    <i className="icon-check">
                                                                        <svg viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M20 6L9 17l-5-5" />
                                                                        </svg>
                                                                    </i>
                                                                    <span> Development </span>
                                                                </li>
                                                                <li className="no-active">
                                                                    <i className="icon-check">
                                                                        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                                                            <g>
                                                                                <path className="cls-1" d="M7 7l18 18M7 25L25 7" />
                                                                            </g>
                                                                        </svg>
                                                                    </i>
                                                                    <span> Design </span>
                                                                </li>
                                                                <li className="no-active">
                                                                    <i className="icon-check">
                                                                        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                                                            <g>
                                                                                <path className="cls-1" d="M7 7l18 18M7 25L25 7" />
                                                                            </g>
                                                                        </svg>
                                                                    </i>
                                                                    <span> 24/7 Support </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="ms-pt--footer">
                                                            <a className="btn btn--ba btn--primary" role="button" href="#">
                                                                <div className="ms-btn__text">
                                                                    <span className="text--main">Choose Plan</span>
                                                                </div>
                                                                <span className="ms-btn--ripple" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clients-area">
                                        <h2 className="clients-title">My Clients</h2>
                                        <div className="clients-inner">
                                            <div id="gallery-1" className="gallery galleryid-1718 gallery-columns-5 gallery-size-medium">
                                                <figure className="gallery-item">
                                                    <div className="gallery-icon landscape">
                                                        <img width={300} height={243} src="assets/images/clients/logo-partner-1.png" className="attachment-medium size-medium" alt decoding="async" loading="lazy" />
                                                    </div>
                                                </figure>
                                                <figure className="gallery-item">
                                                    <div className="gallery-icon landscape">
                                                        <img width={300} height={243} src="assets/images/clients/logo-partner-2.png" className="attachment-medium size-medium" alt decoding="async" loading="lazy" />
                                                    </div>
                                                </figure>
                                                <figure className="gallery-item">
                                                    <div className="gallery-icon landscape">
                                                        <img width={300} height={243} src="assets/images/clients/logo-partner-3.png" className="attachment-medium size-medium" alt decoding="async" loading="lazy" />
                                                    </div>
                                                </figure>
                                                <figure className="gallery-item">
                                                    <div className="gallery-icon landscape">
                                                        <img width={300} height={243} src="assets/images/clients/logo-partner-4.png" className="attachment-medium size-medium" alt decoding="async" loading="lazy" />
                                                    </div>
                                                </figure>
                                                <figure className="gallery-item">
                                                    <div className="gallery-icon landscape">
                                                        <img width={300} height={243} src="assets/images/clients/logo-partner-5.png" className="attachment-medium size-medium" alt decoding="async" loading="lazy" />
                                                    </div>
                                                </figure>
                                                <figure className="gallery-item">
                                                    <div className="gallery-icon landscape">
                                                        <img width={300} height={243} src="assets/images/clients/logo-partner-6.png" className="attachment-medium size-medium" alt decoding="async" loading="lazy" />
                                                    </div>
                                                </figure>
                                                <figure className="gallery-item">
                                                    <div className="gallery-icon landscape">
                                                        <img width={300} height={243} src="assets/images/clients/logo-partner-7.png" className="attachment-medium size-medium" alt decoding="async" loading="lazy" />
                                                    </div>
                                                </figure>
                                                <figure className="gallery-item">
                                                    <div className="gallery-icon landscape">
                                                        <img width={300} height={243} src="assets/images/clients/logo-partner-8.png" className="attachment-medium size-medium" alt decoding="async" loading="lazy" />
                                                    </div>
                                                </figure>
                                                <figure className="gallery-item">
                                                    <div className="gallery-icon landscape">
                                                        <img width={300} height={243} src="assets/images/clients/logo-partner-9.png" className="attachment-medium size-medium" alt decoding="async" loading="lazy" />
                                                    </div>
                                                </figure>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        }
    </div>
  )
}
export default AboutMeComponent