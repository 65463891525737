import React, { useEffect, useState } from 'react'
import Masonry from 'react-masonry-css';
import Api from '../Api';
import { useNavigate } from 'react-router-dom';
const GalleryImages = () => {
    const breakpointColumnsObj = {
        default: 3,
        1100: 3,
        700: 1
    };
    const [Array,SetArray]=useState(Api)
    const [Array1,SetArray1]=useState(Api)
    const[Category,SetCategory]=useState([])
    const[Condition,SetCondition]=useState(false)
    const navigate=useNavigate()
    useEffect(function()
    {
        if(Array1.length!=0)
        { 
           const response= Array1.map(Obj=>Obj["Category"])
           const result= new Set(response)
            SetCategory([...result])
        }
    },[Array1])
    function Change(cate)
    {
       const response=Array1.filter(Obj=>Obj.Category==cate)
        SetArray([...response])
    }
    return (
        <div className='masonry' >
              <div className="ms-portfolio-filter-area  project">
                  <div className="container">
                      <div className="button-group portfolio-filter filters-button-group">
                      <button className="button is-checked" data-filter="*" onClick={()=>SetArray(Array1)}>All Categories</button>
                          <>
                          {
                              Category?Category.map(function(cate,index)
                              {
                                  return(
                                      <button key={index} className="button" onClick={()=>Change(cate)} data-filter=".creative">{cate}</button>
                                  )
                              }):""
                          }
                          </>
                      </div>
                      <div className="portfolio_wrap">
                      <Masonry
                              breakpointCols={breakpointColumnsObj}
                              className="filter grid-masonary row ms-masonry-gallery portfolio-feed"
                              columnClassName="ms-masonry-grid_column">
                              {
                                  Array?.map(function(Obj,index)
                                  {
                                      if(Condition==true)
                                      {
                                          if(index%2==0)
                                          {
                                              return(
                                                  <div key={index} className="fadein zoom center h-align-middle grid-item-p element-item transition design col-md-4" data-category="transition">
                                                      <div className="item--inner">
                                                          <a aria-label="Freezing Birthday">
                                                              <figure className="ms-p-img">
                                                                  <img decoding="async" src={Obj.Image ? Obj.Image : "assets/images/portfolio/02.jpg"} alt="Freezing Birthday" />
                                                              </figure>
                                                              <div className="ms-p-content">
                                                                  <h3>{Obj.Name ? Obj.Name : "Name"}</h3>
                                                                  <h4 className="ms-p-cat">{Obj.Category ? Obj.Category : "Design"}</h4>
                                                              </div>
                                                          </a>
                                                      </div>
                                                  </div>
                                              )
                                          }
                                          else
                                          {
                                              return(
                                                  <div key={index} className="fadein zoom center h-align-middle grid-item-p element-item transition creative col-md-4" data-category="transition">
                                                  <div className="item--inner">
                                                      <a aria-label="Freezing Birthday">
                                                          <figure className="ms-p-img">
                                                              <img decoding="async" src={Obj.Image ? Obj.Image : "assets/images/portfolio/05.jpg"} alt="Freezing Birthday" />
                                                          </figure>
                                                          <div className="ms-p-content">
                                                          <h3>{Obj.Name ? Obj.Name : "Name"}</h3>
                                                          <h4 className="ms-p-cat">{Obj.Category ? Obj.Category : "Design"}</h4>
                                                          </div>
                                                      </a>
                                                  </div>
                                              </div>
                                              )
                                          }
                                      }
                                      else
                                      {
                                         if(index<9)
                                         {
                                          if(index%2==0)
                                          {
                                              return(
                                                  <div key={index} className="fadein zoom center h-align-middle grid-item-p element-item transition design col-md-4" data-category="transition">
                                                      <div className="item--inner">
                                                          <a aria-label="Freezing Birthday">
                                                              <figure className="ms-p-img">
                                                                  <img decoding="async" src={Obj.Image ? Obj.Image : "assets/images/portfolio/02.jpg"} alt="Freezing Birthday" />
                                                              </figure>
                                                              <div className="ms-p-content">
                                                                  <h3>{Obj.Name ? Obj.Name : "Name"}</h3>
                                                                  <h4 className="ms-p-cat">{Obj.Category ? Obj.Category : "Design"}</h4>
                                                              </div>
                                                          </a>
                                                      </div>
                                                  </div>
                                              )
                                          }
                                          else
                                          {
                                              return(
                                                  <div key={index} className="fadein zoom center h-align-middle grid-item-p element-item transition creative col-md-4" data-category="transition">
                                                  <div className="item--inner">
                                                      <a aria-label="Freezing Birthday">
                                                          <figure className="ms-p-img">
                                                              <img decoding="async" src={Obj.Image ? Obj.Image : "assets/images/portfolio/05.jpg"} alt="Freezing Birthday" />
                                                          </figure>
                                                          <div className="ms-p-content">
                                                          <h3>{Obj.Name ? Obj.Name : "Name"}</h3>
                                                          <h4 className="ms-p-cat">{Obj.Category ? Obj.Category : "Design"}</h4>
                                                          </div>
                                                      </a>
                                                  </div>
                                              </div>
                                              )
                                          }
                                         }
                                      }
                                  })
                              }
                              {/* <div className="fadein zoom h-align-middle grid-item-p element-item transition creative col-md-4" data-category="transition">
                                  <div className="item--inner">
                                      <a href="project-single.html" aria-label="Freezing Birthday">
                                          <figure className="ms-p-img">
                                              <img decoding="async" src="assets/images/portfolio/01.jpg" alt="Freezing Birthday" />
                                          </figure>
                                          <div className="ms-p-content">
                                              <h3>Freezing Birthday</h3>
                                              <h4 className="ms-p-cat">creative</h4>
                                          </div>
                                      </a>
                                  </div>
                              </div>
                              <div className="fadein zoom center h-align-middle grid-item-p element-item transition design col-md-4" data-category="transition">
                                  <div className="item--inner">
                                      <a href="project-single.html" aria-label="Freezing Birthday">
                                          <figure className="ms-p-img">
                                              <img decoding="async" src="assets/images/portfolio/02.jpg" alt="Freezing Birthday" />
                                          </figure>
                                          <div className="ms-p-content">
                                              <h3>Stream Shop</h3>
                                              <h4 className="ms-p-cat">design</h4>
                                          </div>
                                      </a>
                                  </div>
                              </div>
                              <div className="fadein zoom center h-align-middle grid-item-p element-item transition photo col-md-4" data-category="transition">
                                  <div className="item--inner">
                                      <a href="project-single.html" aria-label="Freezing Birthday">
                                          <figure className="ms-p-img">
                                              <img decoding="async" src="assets/images/portfolio/03.jpg" alt="Freezing Birthday" />
                                          </figure>
                                          <div className="ms-p-content">
                                              <h3>Random Risk</h3>
                                              <h4 className="ms-p-cat">photo</h4>
                                          </div>
                                      </a>
                                  </div>
                              </div>
                              <div className="fadein zoom center h-align-middle grid-item-p element-item transition style col-md-4" data-category="transition">
                                  <div className="item--inner">
                                      <a href="project-single.html" aria-label="Freezing Birthday">
                                          <figure className="ms-p-img">
                                              <img decoding="async" src="assets/images/portfolio/04.jpg" alt="Freezing Birthday" />
                                          </figure>
                                          <div className="ms-p-content">
                                              <h3>Share Spark</h3>
                                              <h4 className="ms-p-cat">style</h4>
                                          </div>
                                      </a>
                                  </div>
                              </div>
                              <div className="fadein zoom center h-align-middle grid-item-p element-item transition creative col-md-4" data-category="transition">
                                  <div className="item--inner">
                                      <a href="project-single.html" aria-label="Freezing Birthday">
                                          <figure className="ms-p-img">
                                              <img decoding="async" src="assets/images/portfolio/05.jpg" alt="Freezing Birthday" />
                                          </figure>
                                          <div className="ms-p-content">
                                              <h3>Wiggly Finger</h3>
                                              <h4 className="ms-p-cat">creative</h4>
                                          </div>
                                      </a>
                                  </div>
                              </div>
                              <div className="fadein zoom center h-align-middle grid-item-p element-item transition design col-md-4" data-category="transition">
                                  <div className="item--inner">
                                      <a href="project-single.html" aria-label="Freezing Birthday">
                                          <figure className="ms-p-img">
                                              <img decoding="async" src="assets/images/portfolio/06.jpg" alt="Freezing Birthday" />
                                          </figure>
                                          <div className="ms-p-content">
                                              <h3>Subsequent Sneeze</h3>
                                              <h4 className="ms-p-cat">design</h4>
                                          </div>
                                      </a>
                                  </div>
                              </div>
                              <div className="fadein zoom center h-align-middle grid-item-p element-item transition photo col-md-4" data-category="transition">
                                  <div className="item--inner">
                                      <a href="project-single.html" aria-label="Freezing Birthday">
                                          <figure className="ms-p-img">
                                              <img decoding="async" src="assets/images/portfolio/07.png" alt="Freezing Birthday" />
                                          </figure>
                                          <div className="ms-p-content">
                                              <h3>Color Current</h3>
                                              <h4 className="ms-p-cat">photo</h4>
                                          </div>
                                      </a>
                                  </div>
                              </div>
                              <div className="fadein zoom center h-align-middle grid-item-p element-item transition col-md-4" data-category="transition">
                                  <div className="item--inner">
                                      <a href="project-single.html" aria-label="Freezing Birthday">
                                          <figure className="ms-p-img">
                                              <img decoding="async" src="assets/images/portfolio/08.jpg" alt="Freezing Birthday" />
                                          </figure>
                                          <div className="ms-p-content">
                                              <h3>Justice Robot</h3>
                                              <h4 className="ms-p-cat">style</h4>
                                          </div>
                                      </a>
                                  </div>
                              </div>
                              <div className="fadein zoom center h-align-middle grid-item-p element-item transition creative photo  col-md-4" data-category="transition">
                                  <div className="item--inner">
                                      <a href="project-single.html" aria-label="Freezing Birthday">
                                          <figure className="ms-p-img">
                                              <img decoding="async" src="assets/images/portfolio/09.jpg" alt="Freezing Birthday" />
                                          </figure>
                                          <div className="ms-p-content">
                                              <h3>The Dark Side</h3>
                                              <h4 className="ms-p-cat">creative</h4>
                                          </div>
                                      </a>
                                  </div>
                              </div> */}
                          </Masonry>
                      </div>
                      <div className="btn-wrap ajax-area" data-max={2}>
                          <div className="btn btn-load-more btn-circle btn--md">
                              <div className="ms-btn--circle">
                                  <div className="circle">
                                      <div className="circle-fill" />
                                      <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" className="circle-outline">
                                          <circle cx={25} cy={25} r={23} />
                                      </svg>
                                      <div className="circle-icon">
                                          <svg enableBackground="new 0 0 304.891 304.891" version="1.1" viewBox="0 0 304.89 304.89" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
                                              <g fill="#fff">
                                                  <path d="m16.493 112.38c0.346 1.959 1.455 3.7 3.085 4.841 1.27 0.888 2.772 1.355 4.301 1.355 0.434 0 0.871-0.037 1.304-0.114l88.629-15.647c4.079-0.72 6.802-4.61 6.082-8.689l-3.477-19.695c-0.72-4.079-4.608-6.802-8.69-6.082l-36.931 6.52c5.196-6.139 11.076-11.74 17.625-16.754 19.761-15.127 43.36-23.123 68.246-23.123 35.418 0 68.029 16.063 89.469 44.07 15.179 19.828 23.193 43.471 23.175 68.371-3e-3 4.142 3.353 7.502 7.495 7.505l20 0.015h5e-3c1.987 0 3.894-0.789 5.299-2.193 1.408-1.406 2.199-3.313 2.201-5.302 0.023-32.668-10.483-63.676-30.383-89.672-28.114-36.723-70.862-57.786-117.28-57.786-32.635 0-63.583 10.485-89.497 30.323-9.378 7.179-17.861 15.48-25.247 24.642l-6.585-37.299c-0.72-4.079-4.608-6.803-8.69-6.082l-19.695 3.477c-4.079 0.72-6.802 4.61-6.082 8.689l15.646 88.63z" />
                                                  <path d="m288.4 192.51c-0.346-1.958-1.456-3.7-3.085-4.841-1.63-1.14-3.646-1.588-5.604-1.241l-88.629 15.647c-4.079 0.72-6.802 4.61-6.082 8.689l3.477 19.695c0.72 4.079 4.609 6.799 8.689 6.082l36.932-6.52c-5.196 6.14-11.076 11.74-17.625 16.754-19.76 15.127-43.359 23.123-68.245 23.123-35.419 0-68.029-16.063-89.47-44.07-15.179-19.828-23.193-43.471-23.175-68.371 3e-3 -4.142-3.353-7.502-7.495-7.505l-20-0.015h-5e-3c-1.987 0-3.893 0.789-5.299 2.193-1.408 1.406-2.199 3.313-2.201 5.302-0.023 32.668 10.483 63.676 30.383 89.672 28.114 36.724 70.862 57.787 117.28 57.787 32.635 0 63.583-10.486 89.498-30.324 9.378-7.179 17.861-15.48 25.247-24.642l6.585 37.299c0.642 3.638 3.806 6.198 7.377 6.198 0.433 0 0.872-0.038 1.313-0.116l19.695-3.477c4.079-0.72 6.802-4.61 6.082-8.69l-15.647-88.629z" />
                                              </g>
                                          </svg>
                                      </div>
                                  </div>
                                  <div onClick={()=>SetCondition(true)} className="ms-btn--label">
                                      <div className="ms-btn__text">Load More</div>
                                      <div className="ms-btn__border" />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
        </div>
    )
}

export default GalleryImages