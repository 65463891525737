import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import GalleryBackgroundBanner from '../Components/GalleryBackgroundBanner'
import Lifestylecontent from '../Components/Lifestylecontent'

const LifeStyle = () => {
  return (
    <div className='projects1'>
      <Header/>
      <main className="ms-main ms-main-responsive ">
                <GalleryBackgroundBanner title="Street-Style Portraits"/>
                <Lifestylecontent/>
            </main>
      <Footer/>
    </div>
  )
}

export default LifeStyle
