import React from 'react'
import Header from '../Components/Header'
import HomeFourContent from '../Components/HomeFourContent'

const HomeFour = () => {
  return (
    <div className='index-two'>
      <Header/>
      <HomeFourContent/>
    </div>
  )
}

export default HomeFour
