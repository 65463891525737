import React, { useEffect, useState } from 'react'
import Firebase from '../Firebase'
import { Link } from 'react-router-dom'

const Footer = () => {
  const[Obj,SetObj]=useState("")
  const[Obj1,SetObj1]=useState({})
  const[Loader,SetLoader]=useState(true)
  useEffect(function()
  {
    Firebase.child("AdminBio/Email").on("value",function(snap)
    {
      if(snap.val()==null)
      {
        SetObj("")
      }
      else
      {
        SetObj(snap.val())
        setTimeout(() => {
          SetLoader(false);
        }, 1);
      }
    })
    Firebase.child("Settings/SocialMedia").on("value",function(snap)
    {
      if(snap.val()==null)
      {
        SetObj1({})
      }
      else
      {
        SetObj1(snap.val())
      }
    })
  },[])
  return (
    <div>
      {
        Loader?
        <div className="preloader">
          <div className="loader"></div>
        </div>:<footer className="ms-footer ms-footer--template">
        <section className="container footer-container" data-parallax="on">
          <div className="footer-title text-center">
            <h1>Got a <strong>PROJECT</strong> <br /> <strong>IN MIND?</strong> <Link to="/ContactUs" className="btn-footer">Let's Talk <i className="fas fa-arrow-right" /></Link></h1>
          </div>
          <div className="social-area">
            <div className="row area-inner">
              {/* <div className="col-lg-3 col-md-6 col-sm-2 col-2">
                <div className="social-wrapper">
                  <div className="content">
                    <h3 className="platform">Dribble</h3>
                    <a href="#0" className="link">@madsparrow_dev</a>
                  </div>
                  <div className="social">
                    <a href="#0" className="icon"><i className="socicon-dribbble" /></a>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-4 col-md-6 col-sm-2 col-2">
                <div className="social-wrapper">
                  <div className="content">
                    <h3 onClick={()=>window.open(Obj1?.Twitter?Obj1?.Twitter:"","_blank")} className="platform"><a>Linkedin</a></h3>
                    {/* <a href={Obj1.Twitter?Obj1.Twitter:""} className="link"></a> */}
                  </div>
                  <div style={{cursor:"pointer"}} onClick={()=>window.open(Obj1?.Twitter?Obj1?.Twitter:"","_blank")} className="social">
                    <a className="icon"><img style={{height:"25px",marginBottom:"8px"}} src="assets/images/SocialMedia/linkedin (1).png" /></a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-2 col-2">
                <div className="social-wrapper">
                  <div className="content">
                    <h3 className="platform" onClick={()=>window.open(Obj1?.Instagram?Obj1?.Instagram:"","_blank")}><a>Instagram</a></h3>
                    {/* <a href={Obj1.Instagram?Obj1.Instagram:""} className="link"></a> */}
                  </div>
                  <div style={{cursor:"pointer"}} onClick={()=>window.open(Obj1?.Instagram?Obj1?.Instagram:"","_blank")} className="social">
                    <a className="icon"><img style={{height:"23px",marginBottom:"7px",marginLeft:"0.7px"}} src="assets/images/SocialMedia/instagram.png" /></a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-2 col-2">
                <div className="social-wrapper">
                  <div className="content">
                    <h3 onClick={()=>window.open(Obj1?.Facebook?Obj1?.Facebook:"","_blank")} className="platform"><a>Facebook</a></h3>
                    {/* <a href={Obj1.Facebook?Obj1.Facebook:""} className="link"></a> */}
                  </div>
                  <div style={{cursor:"pointer"}} onClick={()=>window.open(Obj1?.Facebook?Obj1?.Facebook:"","_blank")} className="social">
                    <a className="icon"><img style={{height:"30px"}} src='assets/images/SocialMedia/facebook-app-symbol.png' /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright-area">
            <div className="left-side">
              <p>©2024 <a href="mailto:codepulse2021@gmail.com" className="author text-white"> Code Pulse IT Services </a> ,  All Rights Reserved.</p>
            </div>
            <div className="right-side">
              <p><a className='text-white' href={Obj?`mailto:${Obj}`:"Email"}>{Obj?Obj:"Email"}</a></p>
            </div>
          </div>
        </section>
      </footer>
      }
    </div>
  )
}
export default Footer