import React, { useEffect, useState } from 'react'
import Masonry from 'react-masonry-css';
import Firebase from '../Firebase';

const HomeFourContent = () => {
    const breakpointColumnsObj = {
        default: 3,  
    }
    const [Obj,SetObj]=useState({})
    useEffect(function()
    {
        Firebase.child("Settings/Logo").on("value",function(snap)
        {
          if(snap.val()==null)
          {
            SetObj({})
          }
          else
          {
            SetObj(snap.val())
          }
        })
    },[])
  return (
    <div>
        <div className='masonry' >
              <div className="ms-portfolio-filter-area project">
                  <div className="containe boxy">
                      <div className="portfolio_wrap">
                        <div className='logo'>
                            <img src={Obj.Image?Obj.Image:"assets/images/logo/logo-dark.png"} alt="" />
                        </div>
                      <Masonry
                              breakpointCols={breakpointColumnsObj}
                              style={{opacity:0.68}} className="myrows filter grid-masonary my-masonry-grid row ms-masonry-gallery portfolio-feed"
                              columnClassName="ms-masonry-grid_column">
                                                  <div className="fadein zoom center h-align-middle grid-item-p element-item transition design col-md-4" data-category="transition">
                                                      <div className="item--inner">
                                                          <a aria-label="Freezing Birthday">
                                                              <figure className="ms-p-img">
                                                                  <img decoding="async" src="./assets/images/HomePage/1.PNG" alt="Freezing Birthday" />
                                                              </figure>
                                                              <div className="ms-p-content">
                                                                  <h3>Name</h3>
                                                                  <h4 className="ms-p-cat">Design</h4>
                                                              </div>
                                                          </a>
                                                      </div>
                                                  </div>
                                                  <div className="fadein zoom center h-align-middle grid-item-p element-item transition creative col-md-4" data-category="transition">
                                                  <div className="item--inner">
                                                      <a aria-label="Freezing Birthday">
                                                          <figure className="ms-p-img">
                                                              <img decoding="async" src="./assets/images/HomePage/2.PNG" alt="Freezing Birthday" />
                                                          </figure>
                                                          <div className="ms-p-content">
                                                          <h3>Name</h3>
                                                          <h4 className="ms-p-cat">Design</h4>
                                                          </div>
                                                      </a>
                                                  </div>
                                              </div>
                                                  <div className="fadein zoom center h-align-middle grid-item-p element-item transition design col-md-4" data-category="transition">
                                                      <div className="item--inner">
                                                          <a aria-label="Freezing Birthday">
                                                              <figure className="ms-p-img">
                                                                  <img decoding="async" src="./assets/images/HomePage/3.PNG" alt="Freezing Birthday" />
                                                              </figure>
                                                              <div className="ms-p-content">
                                                                  <h3>Name</h3>
                                                                  <h4 className="ms-p-cat">Design"</h4>
                                                              </div>
                                                          </a>
                                                      </div>
                                                  </div>
                                                  <div className="fadein zoom center h-align-middle grid-item-p element-item transition creative col-md-4" data-category="transition">
                                                  <div className="item--inner">
                                                      <a aria-label="Freezing Birthday">
                                                          <figure className="ms-p-img">
                                                              <img decoding="async" src="./assets/images/HomePage/4.PNG" alt="Freezing Birthday" />
                                                          </figure>
                                                          <div className="ms-p-content">
                                                          <h3>Name</h3>
                                                          <h4 className="ms-p-cat">Design</h4>
                                                          </div>
                                                      </a>
                                                  </div>
                                              </div>
                              <div className="fadein zoom h-align-middle grid-item-p element-item transition creative col-md-4" data-category="transition">
                                  <div className="item--inner">
                                      <a aria-label="Freezing Birthday">
                                          <figure className="ms-p-img">
                                              <img decoding="async" src="./assets/images/HomePage/5.PNG" alt="Freezing Birthday" />
                                          </figure>
                                          <div className="ms-p-content">
                                              <h3>Freezing Birthday</h3>
                                              <h4 className="ms-p-cat">creative</h4>
                                          </div>
                                      </a>
                                  </div>
                              </div>
                              <div className="fadein zoom center h-align-middle grid-item-p element-item transition design col-md-4" data-category="transition">
                                  <div className="item--inner">
                                      <a aria-label="Freezing Birthday">
                                          <figure className="ms-p-img">
                                              <img decoding="async" src="./assets/images/HomePage/6.PNG" alt="Freezing Birthday" />
                                          </figure>
                                          <div className="ms-p-content">
                                              <h3>Stream Shop</h3>
                                              <h4 className="ms-p-cat">design</h4>
                                          </div>
                                      </a>
                                  </div>
                              </div>
                              <div className="fadein zoom center h-align-middle grid-item-p element-item transition photo col-md-4" data-category="transition">
                                  <div className="item--inner">
                                      <a aria-label="Freezing Birthday">
                                          <figure className="ms-p-img">
                                              <img decoding="async" src="./assets/images/HomePage/7.PNG" alt="Freezing Birthday" />
                                          </figure>
                                          <div className="ms-p-content">
                                              <h3>Random Risk</h3>
                                              <h4 className="ms-p-cat">photo</h4>
                                          </div>
                                      </a>
                                  </div>
                              </div>
                              <div className="fadein zoom center h-align-middle grid-item-p element-item transition creative col-md-4" data-category="transition">
                                  <div className="item--inner">
                                      <a aria-label="Freezing Birthday">
                                          <figure className="ms-p-img">
                                              <img decoding="async" src="./assets/images/HomePage/8.PNG" alt="Freezing Birthday" />
                                          </figure>
                                          <div className="ms-p-content">
                                              <h3>Wiggly Finger</h3>
                                              <h4 className="ms-p-cat">creative</h4>
                                          </div>
                                      </a>
                                  </div>
                              </div>
                              <div className="fadein zoom center h-align-middle grid-item-p element-item transition design col-md-4" data-category="transition">
                                  <div className="item--inner">
                                      <a aria-label="Freezing Birthday">
                                          <figure className="ms-p-img">
                                              <img decoding="async" src="./assets/images/HomePage/9.PNG" alt="Freezing Birthday" />
                                          </figure>
                                          <div className="ms-p-content">
                                              <h3>Subsequent Sneeze</h3>
                                              <h4 className="ms-p-cat">design</h4>
                                          </div>
                                      </a>
                                  </div>
                              </div>
                              <div className="fadein zoom center h-align-middle grid-item-p element-item transition photo col-md-4" data-category="transition">
                                  <div className="item--inner">
                                      <a aria-label="Freezing Birthday">
                                          <figure className="ms-p-img">
                                              <img decoding="async" src="./assets/images/HomePage/10.PNG" alt="Freezing Birthday" />
                                          </figure>
                                          <div className="ms-p-content">
                                              <h3>Color Current</h3>
                                              <h4 className="ms-p-cat">photo</h4>
                                          </div>
                                      </a>
                                  </div>
                              </div>
                              <div className="fadein zoom center h-align-middle grid-item-p element-item transition style col-md-4" data-category="transition">
                                  <div className="item--inner">
                                      <a  aria-label="Freezing Birthday">
                                          <figure className="ms-p-img">
                                              <img decoding="async" src="./assets/images/HomePage/11.PNG" alt="Freezing Birthday" />
                                          </figure>
                                          <div className="ms-p-content">
                                              <h3>Share Spark</h3>
                                              <h4 className="ms-p-cat">style</h4>
                                          </div>
                                      </a>
                                  </div>
                              </div>
                              <div className="fadein zoom center h-align-middle grid-item-p element-item transition creative photo  col-md-4" data-category="transition">
                                  <div className="item--inner">
                                      <a aria-label="Freezing Birthday">
                                          <figure className="ms-p-img">
                                              <img decoding="async" src="./assets/images/HomePage/12.PNG" alt="Freezing Birthday" />
                                          </figure>
                                          <div className="ms-p-content">
                                              <h3>The Dark Side</h3>
                                              <h4 className="ms-p-cat">creative</h4>
                                          </div>
                                      </a>
                                  </div>
                              </div>
                              <div className="fadein zoom center h-align-middle grid-item-p element-item transition creative photo  col-md-4" data-category="transition">
                                  <div className="item--inner">
                                      <a aria-label="Freezing Birthday">
                                          <figure className="ms-p-img">
                                              <img decoding="async" src="./assets/images/HomePage/13.PNG" alt="Freezing Birthday" />
                                          </figure>
                                          <div className="ms-p-content">
                                              <h3>The Dark Side</h3>
                                              <h4 className="ms-p-cat">creative</h4>
                                          </div>
                                      </a>
                                  </div>
                              </div>
                              <div className="fadein zoom center h-align-middle grid-item-p element-item transition creative photo  col-md-4" data-category="transition">
                                  <div className="item--inner">
                                      <a aria-label="Freezing Birthday">
                                          <figure className="ms-p-img">
                                              <img decoding="async" src="./assets/images/HomePage/14.PNG" alt="Freezing Birthday" />
                                          </figure>
                                          <div className="ms-p-content">
                                              <h3>The Dark Side</h3>
                                              <h4 className="ms-p-cat">creative</h4>
                                          </div>
                                      </a>
                                  </div>
                              </div>
                              <div className="fadein zoom center h-align-middle grid-item-p element-item transition creative photo  col-md-4" data-category="transition">
                                  <div className="item--inner">
                                      <a aria-label="Freezing Birthday">
                                          <figure className="ms-p-img">
                                              <img decoding="async" src="./assets/images/HomePage/15.PNG" alt="Freezing Birthday" />
                                          </figure>
                                          <div className="ms-p-content">
                                              <h3>The Dark Side</h3>
                                              <h4 className="ms-p-cat">creative</h4>
                                          </div>
                                      </a>
                                  </div>
                              </div>
                          </Masonry>
                      </div>
                  </div>
              </div>
        </div>
    </div>
  )
}
export default HomeFourContent