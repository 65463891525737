import React from 'react'
import AdminHeader from '../AdminComponents/AdminHeader'
import Footer from '../Components/Footer'
import ContactDetail from '../AdminComponents/ContactDetail'

const Contact = () => {
  return (
    <div>
      <AdminHeader/>
      <ContactDetail/>
      <Footer/>
    </div>
  )
}
export default Contact