import React from 'react'
import AdminHeader from '../AdminComponents/AdminHeader'
import Footer from '../Components/Footer'
import SettingsComponent from '../AdminComponents/SettingsComponent'

const Settings = () => {
  return (
    <div>
      <AdminHeader/>
      <SettingsComponent/>
      <Footer/>
    </div>
  )
}
export default Settings