import React from 'react'

const ServicesComponent = () => {
  return (
    <div>
<main className="ms-main ms-main-responsive">
  <div className="ms-page-content">
    {/*================= Banner Area Start =================*/}
    <section className="ms-hero services">
      <div className="ms-parallax jarallax-img" data-speed="0.7" data-type="scroll">
        <div className="ms-hc">
          <div className="ms-hc--inner">
            <h1 className="ms-hero-title">Services</h1>
            <p className="ms-hero-subtitle">Distinctively revolutionize<br /> unique deliverables.</p>
          </div>
        </div>
      </div>
    </section>
    {/*================= Banner Area End =================*/}
    {/*================= Services Area Start =================*/}
    <div className="services-area-2 services">
      <div className="container">
        <div className="services-area-inner">
          <div className="services-bottom">
            <div className="services-item">
              <div className="row">
                <div className="col-lg-4 col-md-6 text-center">
                  <div className="ms-sb img-top">
                    <div className="ms-sb--img boxed one">
                      <img src="assets/images/services/icon/code-1.svg" className="attachment-full size-full" alt="image" />
                    </div>
                    <div className="ms-sb--inner">
                      <h3 className="ms-sb--title">
                        <span>Development</span>
                      </h3>
                      <p className="ms-sb--text">Create a platform with the best and coolest
                        quality from us.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 text-center">
                  <div className="ms-sb img-top">
                    <div className="ms-sb--img boxed two">
                      <img src="assets/images/services/icon/code-2.svg" className="attachment-full size-full" alt="image" />
                    </div>
                    <div className="ms-sb--inner">
                      <h3 className="ms-sb--title">
                        <span>UI/UX Designer</span>
                      </h3>
                      <p className="ms-sb--text">Create a platform with the best and coolest
                        quality from us.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 text-center">
                  <div className="ms-sb img-top">
                    <div className="ms-sb--img boxed three">
                      <img src="assets/images/services/icon/code-5.svg" className="attachment-full size-full" alt="image" />
                    </div>
                    <div className="ms-sb--inner">
                      <h3 className="ms-sb--title">
                        <span>Graphics Designer</span>
                      </h3>
                      <p className="ms-sb--text">Create a platform with the best and coolest
                        quality from us.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 text-center">
                  <div className="ms-sb img-top">
                    <div className="ms-sb--img boxed four">
                      <img src="assets/images/services/icon/code-3.svg" className="attachment-full size-full" alt="image" />
                    </div>
                    <div className="ms-sb--inner">
                      <h3 className="ms-sb--title">
                        <span>Motion Graphic</span>
                      </h3>
                      <p className="ms-sb--text">Create a platform with the best and coolest
                        quality from us.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 text-center">
                  <div className="ms-sb img-top">
                    <div className="ms-sb--img boxed five">
                      <img src="assets/images/services/icon/code-4.svg" className="attachment-full size-full" alt="image" />
                    </div>
                    <div className="ms-sb--inner">
                      <h3 className="ms-sb--title">
                        <span>Photography</span>
                      </h3>
                      <p className="ms-sb--text">Create a platform with the best and coolest
                        quality from us.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 text-center">
                  <div className="ms-sb img-top">
                    <div className="ms-sb--img boxed six">
                      <img src="assets/images/services/icon/code-6.svg" className="attachment-full size-full" alt="image" />
                    </div>
                    <div className="ms-sb--inner">
                      <h3 className="ms-sb--title">
                        <span>Videography</span>
                      </h3>
                      <p className="ms-sb--text">Create a platform with the best and coolest
                        quality from us.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 text-center">
                  <div className="ms-sb img-top">
                    <div className="ms-sb--img boxed one">
                      <img src="assets/images/services/icon/code-1.svg" className="attachment-full size-full" alt="image" />
                    </div>
                    <div className="ms-sb--inner">
                      <h3 className="ms-sb--title">
                        <span>Development</span>
                      </h3>
                      <p className="ms-sb--text">Create a platform with the best and coolest
                        quality from us.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 text-center">
                  <div className="ms-sb img-top">
                    <div className="ms-sb--img boxed two">
                      <img src="assets/images/services/icon/code-2.svg" className="attachment-full size-full" alt="image" />
                    </div>
                    <div className="ms-sb--inner">
                      <h3 className="ms-sb--title">
                        <span>UI/UX Designer</span>
                      </h3>
                      <p className="ms-sb--text">Create a platform with the best and coolest
                        quality from us.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 text-center">
                  <div className="ms-sb img-top">
                    <div className="ms-sb--img boxed three">
                      <img src="assets/images/services/icon/code-5.svg" className="attachment-full size-full" alt="image" />
                    </div>
                    <div className="ms-sb--inner">
                      <h3 className="ms-sb--title">
                        <span>Graphics Designer</span>
                      </h3>
                      <p className="ms-sb--text">Create a platform with the best and coolest
                        quality from us.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/*================= Services Area End =================*/}
  </div>
</main>
    </div>
  )
}

export default ServicesComponent