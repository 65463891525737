import React from 'react'
import Header from '../Components/Header'
import ContactUsContent from '../Components/ContactUsContent'
import Footer from '../Components/Footer'

const ContactUs = () => {
  return (
    <div>
        <Header/>
        <ContactUsContent/>
        <Footer/>
    </div>
  )
}

export default ContactUs