import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import GalleryBackgroundBanner from '../Components/GalleryBackgroundBanner'
import Portraitcontent from '../Components/Portraitcontent'

const Portraits = () => {
  return (
    <div className='projects1'>
      <Header/>
      <main className="ms-main ms-main-responsive ">
                <GalleryBackgroundBanner title="Portrait Gallery"/>
                <Portraitcontent/>
            </main>
      <Footer/>
    </div>
  )
}

export default Portraits
