import React from 'react'
import GalleryBackgroundBanner from '../Components/GalleryBackgroundBanner'
import GalleryImages from '../Components/GalleryImages'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

const Gallery = () => {
  return (
    <div className='projects1' >
            <Header />
            <main className="ms-main ms-main-responsive ">
                <GalleryBackgroundBanner/>
                <GalleryImages/>
            </main>
            <Footer/>
        </div>
  )
}

export default Gallery