import React, { useRef, useState } from 'react'
import Firebase from '../Firebase'

const AddAboutDetails = () => {
  const [Obj,SetObj]=useState({
    Name:"",
    Phone:"",
    Email:"",
    Age:"",
    City:"",
    Specification:"",
    Bio:"",
    ProfileImage:"",
    Service_Title_1:"",
    Service_Description_1:"",
    Service_Title_2:"",
    Service_Description_2:"",
    Service_Title_3:"",
    Service_Description_3:"",
    Service_Title_4:"",
    Service_Description_4:""
  })
  const[Error,SetError]=useState(true)
  const phn=useRef()
  const image=useRef()
  function set(event)
  {
    SetObj({...Obj,[event.target.name]:event.target.value.trim()})
  }
  function Send(e)
  {
    e.preventDefault()
    if(Obj.Name!="" && Obj.Age!="" && Obj.City!="" && Obj.Specification!="" && Obj.Phone!="" && Obj.Email!="" && Obj.Bio!="")
    {
        if(Error==false)
        {
          if(Obj.ProfileImage!="")
          {
            if(Obj.Service_Title_1!="" && Obj.Service_Title_2!="" && Obj.Service_Title_3!="" && Obj.Service_Title_4!="" && Obj.Service_Description_1!="" && Obj.Service_Description_2!="" && Obj.Service_Description_3!="" && Obj.Service_Description_4!="")
            {
              Firebase.child("AdminBio").set(Obj,err=>{
                if(err)
                {
                    alert("Some Error Occured")
                }
                else
                {
                    alert("Your details is Saved.")
                }
            })
            }
            else
            {
              alert("Enter your Service and their Description.")
            }
          }
          else
          {
            alert("Upload your Profile Image")
          }
        }
        else
        {
            alert("Enter a valid Phone Number")
        }
    }
    else
    {
        alert("Field is Empty")
    }
  }
  function check(e)
  {
    if(e.target.value=="")
    {
        SetError(true)
        SetObj({...Obj,"Phone":""})
        phn.current.innerHTML="Enter Phone Number"
        phn.current.style.color="orange"
        phn.current.style.marginTop="5px"
    }
    else
    {
        if(e.target.value.length==10)
        {
            SetError(false)
            SetObj({...Obj,"Phone":e.target.value.trim()})
            phn.current.innerHTML="Phone Number is perfect"
            phn.current.style.color="green"
            phn.current.style.marginTop="5px"
        }
        else
        {
            SetError(true)
            SetObj({...Obj,"Phone":""})
            phn.current.innerHTML="Enter a valid Phone Number"
            phn.current.style.color="red"
            phn.current.style.marginTop="5px"
        }
    }
  }
//   function check1(e)
//   {
//     if(e.target.value=="")
//     {
//         SetError1(true)
//         SetObj({...Obj,"Phone1":""})
//         phn1.current.innerHTML="Enter Phone Number"
//         phn1.current.style.color="orange"
//         phn1.current.style.marginTop="5px"
//     }
//     else
//     {
//         if(e.target.value.length==10)
//         {
//             SetError1(false)
//             SetObj({...Obj,"Phone1":e.target.value.trim()})
//             phn1.current.innerHTML="Phone Number is perfect"
//             phn1.current.style.color="green"
//             phn1.current.style.marginTop="5px"
//         }
//         else
//         {
//             SetError1(true)
//             SetObj({...Obj,"Phone1":""})
//             phn1.current.innerHTML="Enter a valid Phone Number"
//             phn1.current.style.color="red"
//             phn1.current.style.marginTop="5px"
//         }
//     }
//   }
  function Upload()
  {
    image.current.click()
  }
  function Uploads(event)
  {
    const file=event.target.files[0]
    if(file)
    {
      const ext=file.type.split("/")
      if(ext[0]=="image")
      {
        if(ext[1]=="png" || ext[1]=="jpeg" || ext[1]=="jpg" || ext[1]=="PNG")
        {
          const reader=new FileReader()
          reader.readAsDataURL(file)
          reader.onload=function()
          {
            SetObj({...Obj,"ProfileImage":reader.result})
            alert("Image Uploaded")
          }
        }
        else
        {
          alert("Only jpeg,jpg and png images are allowed.")
        }
      } 
      else
      {
        alert("Only images are allowed to upload.")
      }
    }
  }
  return (
    <div>
           <main className="ms-main ms-main-responsive ">
                <div className="ms-page-content">
                    <div className="container">
                        <div className="personal-cv-area">
                            <div className="row">
                                <div className="col-lg-5">
                                    <div className="image-left-side">
                                        <img src={Obj.ProfileImage?Obj.ProfileImage:"assets/images/portfolio/personal-cv.jpg"} alt="image" />
                                    </div>
                                </div>
                               <div className="col-lg-7">
                               <div className="contact-area contact">
                        <div className="container">
                            <div className="contact-area-inner">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="contact-form">
                                            <form aria-label="Contact form">
                                                <div className="row">
                                                    <div className="form-group col-6">
                                                        <input onChange={set} className='text-white' placeholder="Enter your Name"  type="text" name="Name" required />
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <input placeholder="Enter your Phone Number" className='text-white' type="number" onChange={check} max={10} min={10} name="Phone" required />
                                                        <p ref={phn}></p>
                                                    </div>
                                                    {/* <div className="form-group col-6">
                                                        <input placeholder="Enter your Mobile Number" className='text-white' type="number" onChange={check1} max={10} min={10} name="Phone1" required />
                                                        <p ref={phn1}></p>
                                                    </div> */}
                                                    <div className='form-group col-12'>
                                                    <div className="ms-cf--bottom">
                                                      <input ref={image} onChange={Uploads} type="file" hidden/>
                                                        <input className="btn btn-primary" onClick={Upload} type="Upload" defaultValue="Upload your Image" />
                                                    </div>
                                                    </div>
                                                    <div className="form-group col-12">
                                                        <input onChange={set} className='text-white' placeholder="Enter your Email" type="email" name="Email" required />
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <input onChange={set} className='text-white' placeholder="Enter your Experience" type="number" name="Age" required />
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <input onChange={set} className='text-white' placeholder="Enter your City" type="text" name="City" required />
                                                    </div>
                                                    <div className="form-group col-12">
                                                        <input onChange={set} className='text-white' placeholder="Enter your Specification" type="text" name="Specification" required />
                                                    </div>
                                                    <div className="form-group col-12">
                                                        <textarea onChange={set} className='text-white' placeholder="Enter your Bio" name="Bio"/>
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label >Service-1</label>
                                                        <input onChange={set} className='text-white' placeholder="Enter your Service-Title-1" type="text" name="Service_Title_1" required />
                                                    </div>
                                                    <div className="form-group col-6">
                                                    <label >Description-1</label>
                                                        <input onChange={set} className='text-white' placeholder="Enter your Service-Description-1" type="text" name="Service_Description_1" required />
                                                    </div>
                                                    <div className="form-group col-6">
                                                    <label >Service-2</label>
                                                        <input onChange={set} className='text-white' placeholder="Enter your Service-Title-2" type="text" name="Service_Title_2" required />
                                                    </div>
                                                    <div className="form-group col-6">
                                                    <label >Description-2</label>
                                                        <input onChange={set} className='text-white' placeholder="Enter your Service-Description-2" type="text" name="Service_Description_2" required />
                                                    </div>
                                                    <div className="form-group col-6">
                                                    <label >Service-3</label>
                                                        <input onChange={set} className='text-white' placeholder="Enter your Service-Title-3" type="text" name="Service_Title_3" required />
                                                    </div>
                                                    <div className="form-group col-6">
                                                    <label >Description-3</label>
                                                        <input onChange={set} className='text-white' placeholder="Enter your Service-Description-3" type="text" name="Service_Description_3" required />
                                                    </div>
                                                    <div className="form-group col-6">
                                                    <label >Service-4</label>
                                                        <input onChange={set} className='text-white' placeholder="Enter your Service-Title-4" type="text" name="Service_Title_4" required />
                                                    </div>
                                                    <div className="form-group col-6">
                                                    <label >Description-4</label>
                                                        <input onChange={set} className='text-white' placeholder="Enter your Service-Description-4" type="text" name="Service_Description_4" required />
                                                    </div>
                                                    <div className="text-center ms-cf--bottom">
                                                        <button className="btn btn-primary" onClick={Send}>Submit</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
    </div>
  )
}
export default AddAboutDetails